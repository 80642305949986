import React, { useState } from 'react'
import ReactTooltip from "react-tooltip";

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ControlPointDuplicateIcon from '@mui/icons-material/ControlPointDuplicate';
import { IAppCompanySubscription } from '../../../entities/company-entity';
import TableCompanySubscriptionsContainer from './table-company-subscriptions-style';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel } from '@mui/material';

interface ITableKeys {
  name: string;
  id: string;
  options?: {
    sort?: boolean
  }
}

const COMPANY_SUBSCRIPTIONS_TABLE_KEYS: ITableKeys[] = [
  {
    name: 'Name',
    id: 'name',
  },
  {
    name: 'Price',
    id: 'price'
  },
  {
    name: 'Interval',
    id: 'interval',
  },
  {
    name: 'Period start',
    id: 'currentPeriodStart',
  },
  {
    name: 'Period end',
    id: 'currentPeriodEnd',
  },
  {
    name: 'Status',
    id: 'status'
  },
  {
    name: 'Created at',
    id: 'createdAt'
  },
  {
    name: 'Updated at',
    id: 'updatedAt'
  },
  {
    name: 'Canceled at',
    id: 'CanceledAt'
  }

]

const getPricePerUserSubscription = (subscription: IAppCompanySubscription) => {
  const amountInCents = subscription?.service_plan_price?.amount_in_cents_per_user
  return amountInCents ? `$ ${amountInCents / 100}` : '$ 0.00'
}

const getNextCyclePriceSubscription = (subscription: any) => {
  const amountInCents = subscription?.service_plan_price?.next_cycle_total?.amount_in_cents_per_user
  return amountInCents ? `$ ${amountInCents / 100}` : '$ 0.00'
}

const formatTableDate = (isoStringDate) => {
  const date = new Date(isoStringDate);
  const options = {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false
  }
  return date.toLocaleDateString('en-EN', options as any);
}


interface TableCompanySubscriptionsProps {
  subscriptions: IAppCompanySubscription[]
  options?: {
    pageLength?: number
  }
}

const usePagination = (props: TableCompanySubscriptionsProps) => {
  const { options, subscriptions } = props
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(options?.pageLength || subscriptions.length);

  const getPaginatedRows = () => {
    if (options?.pageLength) {

      return subscriptions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    }

    return subscriptions
  }

  return {
    page,
    setPage,
    rowsPerPage,
    getPaginatedRows
  }

}

const TableCompanySubscriptions: React.FC<TableCompanySubscriptionsProps> = (props: TableCompanySubscriptionsProps) => {
  const { subscriptions, options } = props;

  const {
    page,
    setPage,
    rowsPerPage,
    getPaginatedRows
  } = usePagination(props)

  return <TableCompanySubscriptionsContainer>
    <TableContainer>
      <Table
        sx={{ minWidth: 750 }}
        aria-labelledby="table-company-subscriptions"
      >
        <TableHead>
          <TableRow>
            {COMPANY_SUBSCRIPTIONS_TABLE_KEYS.map((tableKey) =>
              <TableCell
                key={tableKey.id}
                align={tableKey.id === 'actions' ? 'right' : 'left'}
                sortDirection={'desc'}
              >
                {
                  tableKey?.options?.sort
                    ? < TableSortLabel
                      active={true}
                      direction={'desc'}
                      onClick={() => { }}
                    >
                      {tableKey.name}
                    </TableSortLabel>
                    : <>{tableKey.name}</>
                }
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {subscriptions.length > 0 &&
            getPaginatedRows()
              .map((subscription, index) =>
                <TableRow
                  hover
                  onClick={(event) => { }}
                  role="checkbox"
                  tabIndex={-1}
                  key={'-'}
                >
                  <TableCell
                    component="th"
                    id={'-'}
                    scope="row"
                  >
                    -
                  </TableCell>

                  <TableCell
                    align="left"
                  >
                    {getPricePerUserSubscription(subscription) || '-'}
                  </TableCell>
                  <TableCell
                    align="left"
                  >
                    {`${subscription.service_plan_price?.interval_count} ${subscription.service_plan_price?.interval}` || '-'}
                  </TableCell>
                  <TableCell
                    align="left"
                  >
                    {subscription.current_period_start ? formatTableDate(subscription.current_period_start) : '-'}
                  </TableCell>
                  <TableCell
                    align="left"
                  >
                    {subscription.current_period_end ? formatTableDate(subscription.current_period_end) : '-'}

                  </TableCell>
                  <TableCell
                    align="left"
                  >
                    {subscription.status || '-'}
                  </TableCell>

                  <TableCell
                    align="left"
                  >
                    {subscription.created_at ? formatTableDate(subscription.created_at) : '-'}
                  </TableCell>

                  <TableCell
                    align="left"
                  >
                    {subscription.updated_at ? formatTableDate(subscription.updated_at) : '-'}
                  </TableCell>

                  <TableCell
                    align="left"
                  >
                    {subscription.canceled_at ? formatTableDate(subscription.canceled_at) : '-'}
                  </TableCell>

                </TableRow>
              )}
        </TableBody>
      </Table>
    </TableContainer>
    {options?.pageLength && <TablePagination
      rowsPerPageOptions={[options.pageLength]}
      component="div"
      count={subscriptions.length}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={(event, newPage) => setPage(newPage)}
    />}
  </TableCompanySubscriptionsContainer >
}

export default TableCompanySubscriptions