import axios, { AxiosPromise } from 'axios'
import { getHeaders, SERVICE_URL } from '../../adapters/dime-api-adapter';
import {  DashboardApiUpdatePolicyInput, Policy } from '../../adapters/dime-api-client';

export const updatePolicyRequest = (
  token: string,
  id: string,
  body: DashboardApiUpdatePolicyInput,
): AxiosPromise<Policy> => {
  console.log(SERVICE_URL, 'SERVICE')
  const endpoint = `${SERVICE_URL}/dashboard-api/policies/${id}/update/`;

  return axios({
    method: "PUT",
    url: endpoint,
    headers: getHeaders(token),
    data: body,
  });
};