import { TableContainer, Table, TableHead, TableRow, TableCell, Button, TableSortLabel, TableBody, TablePagination } from "@mui/material";
import React, { useEffect, useState } from "react";

import { IAppPolicyBreachList } from "../../../entities/policy-breach-entity";
import { DashboardApiMonitoringSummaryOutput, DashboardApiMonitoringUsersListViewOutput } from "../../../lib/dime-api/adapters/dime-api-client";
import TableMonitoringUsersContainer from "./table-monitoring-users-style";

interface ITableKeys {
  name: string;
  id: string;
  options?: {
    sort?: boolean
  }
}

const POLICY_BREACH_TABLE_KEYS: ITableKeys[] = [
  {
    name: 'Email',
    id: 'email',
  },
  {
    name: 'Ride count',
    id: 'rideCount',
  },
  {
    name: 'Rides finished count',
    id: 'ridesFinishedCount',
  },
  {
    name: 'Policy violations',
    id: 'policyViolations',
  },
  {
    name: 'Savings made',
    id: 'savingsMade',
  },
  {
    name: 'Total expense',
    id: 'totalExpense',
  },
  {
    name: 'Underspend comparable service',
    id: 'underspendComparableService',
  },
  {
    name: 'Overspend comparable service',
    id: 'overspendComparableService',
  }
]


interface TableMonitoringUsersProps {
  monitoringUsers: DashboardApiMonitoringUsersListViewOutput[]
  options?: {
    pageLength?: number
  }
}

const formatTableDate = (isoStringDate) => {
  const date = new Date(isoStringDate);
  const options = {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false
  }
  return date.toLocaleDateString('en-EN', options as any);
}

const usePagination = (props: TableMonitoringUsersProps) => {
  const { options, monitoringUsers } = props
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(options?.pageLength || monitoringUsers.length);

  useEffect(() => {
    console.log('cambio', monitoringUsers)
  }, [monitoringUsers])

  const getPaginatedRows = () => {
    if (options?.pageLength) {
      return monitoringUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    }


    return monitoringUsers
  }

  return {
    page,
    setPage,
    rowsPerPage,
    getPaginatedRows
  }

}
const TableMonitoringUsers: React.FC<TableMonitoringUsersProps> = (props: TableMonitoringUsersProps) => {
  const { monitoringUsers, options } = props;
  const {
    page,
    setPage,
    rowsPerPage,
    getPaginatedRows
  } = usePagination(props)

  return <TableMonitoringUsersContainer>
    <TableContainer>
      <Table
        sx={{ minWidth: 750 }}
        aria-labelledby="table-policies"
      >
        <TableHead>
          <TableRow>
            {POLICY_BREACH_TABLE_KEYS.map((tableKey) =>
              <TableCell
                key={tableKey.id}
                align={tableKey.id === 'actions' ? 'right' : 'left'}
                sortDirection={'desc'}
              >
                {
                  tableKey.options?.sort
                    ? < TableSortLabel
                      active={true}
                      direction={'desc'}
                      onClick={() => { }}
                    >
                      {tableKey.name}
                    </TableSortLabel>
                    : <>{tableKey.name}</>
                }
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {monitoringUsers.length > 0 &&
            getPaginatedRows()
              .map((monitoringUser, index) =>
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={index}
                >
                  <TableCell
                    component="th"
                    scope="row"
                  >
                    {monitoringUser.email}
                  </TableCell>

                  <TableCell
                    align="left"
                  >
                    {monitoringUser.ride_count || 'Default'}
                  </TableCell>
                  <TableCell
                    align="left"
                  >
                    {monitoringUser.rides_finished_count || '-'}
                  </TableCell>
                  <TableCell
                    align="left"
                  >
                    {monitoringUser.policy_violations || '-'}

                  </TableCell>
                  <TableCell
                    align="left"
                  >
                    {monitoringUser.savings_made_in_cents ? `$${monitoringUser.savings_made_in_cents / 100}` : '-'}

                  </TableCell>
                  <TableCell
                    align="left"
                  >
                    {monitoringUser.total_expense_in_cents ? `$${monitoringUser.total_expense_in_cents / 100}` : '-'}

                  </TableCell>
                  <TableCell
                    align="left"
                  >
                    {monitoringUser.underspend_comparable_service_in_cents ? `$${monitoringUser.underspend_comparable_service_in_cents / 100}` : '-'}

                  </TableCell>
                  <TableCell
                    align="left"
                  >
                    {monitoringUser.overspend_comparable_service_in_cents ? `$${monitoringUser.overspend_comparable_service_in_cents / 100}` : '-'}

                  </TableCell>
                  <TableCell
                    align="left"
                  >
                    {monitoringUser.vs_comparable_service_in_cents ? `$${monitoringUser.vs_comparable_service_in_cents / 100}` : '-'}

                  </TableCell>
                </TableRow>
              )}
        </TableBody>
      </Table>
    </TableContainer>

    {options?.pageLength && <TablePagination
      rowsPerPageOptions={[options.pageLength]}
      component="div"
      count={monitoringUsers.length}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={(event, newPage) => setPage(newPage)}
    />}
  </TableMonitoringUsersContainer >
}

export default TableMonitoringUsers