import axios, { AxiosPromise } from 'axios'
import { getHeaders, SERVICE_URL } from '../../adapters/dime-api-adapter';
import { LoginApiViewRequest, LoginApiViewResponse } from '../../adapters/dime-api-client';

export const loginUserRequest = (
  body: LoginApiViewRequest,
): AxiosPromise<LoginApiViewResponse> => {
  console.log(SERVICE_URL, 'SERVICE')
  const endpoint = `${SERVICE_URL}/auth-api/login/`;

  return axios({
    method: "POST",
    url: endpoint,
    headers: getHeaders(),
    data: body,
  });
};