import { Typography, TextField, Button } from '@mui/material';
import React, { useContext } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import Svg from '../../components/svg/svg';
import { routes } from '../../constants/routes';
import useForm from '../../hooks/useForm/use-form';
import { IFormValidations, ISubmitUseFormParams, IValidation } from '../../hooks/useForm/use-form-types';
import { RequiredValidation } from '../../hooks/useForm/use-form-validations';
import { StoreContext } from '../../lib/store/store';
import { IStoreContext } from '../../lib/store/store-types';
import { useAuthService } from '../../services/auth/auth-service';
import NewPasswordContainer from './new-password-style'
import logoIcon from '../../assets/icons/files/small-logo.svg'

interface IFormValues {
  password: string;
}

const requiredValidation: IValidation = RequiredValidation();

const formValidations: IFormValidations<IFormValues> = {
  password: [requiredValidation],
}

const useRequestPassword = () => {
  const authService = useAuthService()
  const navigate = useNavigate()
  const store = useContext<IStoreContext>(StoreContext)
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get("token")
  const uid = searchParams.get("uid")


  const initialValues: IFormValues = {
    password: '',
  }

  const submit = async (params: ISubmitUseFormParams<IFormValues>) => {

    try {
      await authService.resetPassword({
        password: params.values.password,
        token,
        uid64: uid
      })
      const successText = 'Password changed successfully'
      store.setModalInfo({
        open: true,
        text: successText
      })

      navigate(routes.login)
    } catch (error) {
      const errorText = 'An error has been occurred changing the password'
      store.setModalError({
        open: true,
        text: errorText
      })
    }

  }

  const formActions = useForm<IFormValues>(
    {
      initialValues,
      formValidations,
      submit
    }
  )

  return {
    ...formActions
  }

}

const NewPasswordPage: React.FC = () => {
  const actions = useRequestPassword();

  return <NewPasswordContainer>
    <section className="request-password-section">
      <div className="request-password-logo">
        <Svg
          src={logoIcon}
        />
      </div>
      <Typography
        component="h1"
        variant="h4"
        className="request-password-title"
      >
        Forgot password?
      </Typography>

      <form
        className="request-password-form"
        id='request-password-form'
        onSubmit={(event) => {
          event.preventDefault();
          actions.onSubmit()
        }}
      >
        <TextField
          className="request-password-field"
          required
          fullWidth
          id="password"
          label="New Password"
          name="password"
          autoFocus
          type='password'
          onChange={(event) => actions.handleFieldEvent(event.target.value, "password")}
        />

      </form>

      <Button
        className="request-password-button"
        type="submit"
        fullWidth
        variant="contained"
        color="success"
        form="request-password-form"
      >
        Reset password
      </Button>


    </section>
  </NewPasswordContainer>
}

export default NewPasswordPage 