import { createContext } from "react"
import modalError from "../../components/modals/modal-error/modal-error"
import { useLoadingContext } from "./loading/loading-store"
import { INITIAL_MODAL_CONTEXT, useModalContext } from "./modal/modal-store"
import { useProfileContext } from "./profile/profile-store"
import { IStoreContext } from "./store-types"

const DEFAULT_STORE_CONTEXT = {
  profile: undefined,
  setProfile: () => {},
  loading: false,
  setLoading: () => {},
  ...INITIAL_MODAL_CONTEXT
}

export const useStoreContext = (): IStoreContext => {
  const profileContext = useProfileContext()
  const loadingContext = useLoadingContext()
  const modalContext = useModalContext()
  return {
    ...profileContext,
    ...loadingContext,
    ...modalContext
  }
}

export const StoreContext = createContext<IStoreContext>(DEFAULT_STORE_CONTEXT)