import axios, { AxiosPromise } from 'axios'
import { getHeaders, SERVICE_URL } from '../../adapters/dime-api-adapter';
import { Success } from '../../adapters/dime-api-client';

export const logoutUserRequest = (
  token: string
): AxiosPromise<Success> => {
  const endpoint = `${SERVICE_URL}/auth-api/logout/`;

  return axios({
    method: "GET",
    url: endpoint,
    headers: getHeaders(token),
  });
};