import { Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import MainTabs from '../../../components/main-tabs/main-tabs'
import { usePolicyBreachService } from '../../../services/policy-breach/policy-breach-service'
import PolicyBreachesDetailContainer from './policy-breaches-detail-style'
import ballTriangleLoader from '../../../assets/icons/files/ball-triangle-loader.svg'
import Svg from '../../../components/svg/svg'


const usePolicyBreachesDetail = () => {
  const policyBreachService = usePolicyBreachService()
  const params = useParams();
  const { data: policyBreach, error, isLoading } = useQuery(
    ["policyBreach"],
    () => policyBreachService.getPolicyBreachById(params.id),
    { retry: false },
  )

  useEffect(() => {
    console.log(policyBreach)
  }, [policyBreach])

  return {
    policyBreach,
    isPolicyBreachLoading: isLoading
  }
}

const PolicyBreachesDetail: React.FC = () => {
  const {
    policyBreach,
    isPolicyBreachLoading
  } = usePolicyBreachesDetail()

  return <PolicyBreachesDetailContainer>
    <Typography
      component="h1"
      variant="h4"
      className="policy-breach-title"
    >
      Policy breach
    </Typography>

    <section className="policy-breach-section shadow">
      {isPolicyBreachLoading ?
        <div className="component-loader">
          <Svg src={ballTriangleLoader} />
        </div>
        : <article className="policy-breach-article">

          <ul className="policy-breach-fields">
            <li className="policy-breach-field">
              <Typography
                component="p"
                variant="body1"
                className="policy-breach-label"
              >
                USER
              </Typography>

              <Typography
                component="p"
                variant="body1"
                className="policy-breach-text"
              >
                {policyBreach?.user?.email || '-'}
              </Typography>
            </li>

            <li className="policy-breach-field">
              <Typography
                component="p"
                variant="body1"
                className="policy-breach-label"
              >
                POLICY BREACH TYPE
              </Typography>

              <Typography
                component="p"
                variant="body1"
                className="policy-breach-text"
              >
                {policyBreach?.type || '-'}
              </Typography>
            </li>
          </ul>
        </article>
      }
    </section>

    <section className="policy-breach-section shadow">
      {isPolicyBreachLoading ?
        <div className="component-loader">
          <Svg src={ballTriangleLoader} />
        </div>
        : <article className="policy-breach-article">
          <Typography
            component="h2"
            variant="h5"
            className="policy-breach-article-title"
          >
            Policy
          </Typography>

          <ul className="policy-breach-fields">
            <li className="policy-breach-field">
              <Typography
                component="p"
                variant="body1"
                className="policy-breach-label"
              >
                NAME
              </Typography>

              <Typography
                component="p"
                variant="body1"
                className="policy-breach-text"
              >
                {policyBreach?.policy?.name || '-'}
              </Typography>
            </li>

            <li className="policy-breach-field">
              <Typography
                component="p"
                variant="body1"
                className="policy-breach-label"
              >
                MAX MONTHLY EXPENSE
              </Typography>

              <Typography
                component="p"
                variant="body1"
                className="policy-breach-text"
              >
                {policyBreach?.policy?.max_monthly_expense || '-'}
              </Typography>
            </li>

            <li className="policy-breach-field">
              <Typography
                component="p"
                variant="body1"
                className="policy-breach-label"
              >
                FROM TIME
              </Typography>

              <Typography
                component="p"
                variant="body1"
                className="policy-breach-text"
              >
                {policyBreach?.policy?.from_time || '-'}
              </Typography>
            </li>


            <li className="policy-breach-field">
              <Typography
                component="p"
                variant="body1"
                className="policy-breach-label"
              >
                TO TIME
              </Typography>

              <Typography
                component="p"
                variant="body1"
                className="policy-breach-text"
              >
                {policyBreach?.policy?.to_time || '-'}
              </Typography>
            </li>
          </ul>
        </article>
      }

    </section>

    <MainTabs
      tabs={[
        "Ride detail",
        "Justification",
        "Selected estimated",
        "Violation warnings",
        "Estimates"
      ]}
      components={[
        <>
          {isPolicyBreachLoading ?
            <div className="component-loader">
              <Svg src={ballTriangleLoader} />
            </div>
            : <section className="policy-breach-section shadow">
              <article className="policy-breach-article">
                <ul className="policy-breach-fields">
                  <li className="policy-breach-field">
                    <Typography
                      component="p"
                      variant="body1"
                      className="policy-breach-label"
                    >
                      ORIGIN ADDRESS
                    </Typography>

                    <Typography
                      component="p"
                      variant="body1"
                      className="policy-breach-text"
                    >
                      {(policyBreach?.estimate as any)?.ride?.origin_address || '-'}
                    </Typography>
                  </li>

                  <li className="policy-breach-field">
                    <Typography
                      component="p"
                      variant="body1"
                      className="policy-breach-label"
                    >
                      DESTINATION ADDRESS
                    </Typography>

                    <Typography
                      component="p"
                      variant="body1"
                      className="policy-breach-text"
                    >
                      {(policyBreach?.estimate as any)?.ride?.destination_address || '-'}
                    </Typography>
                  </li>

                  <li className="policy-breach-field">
                    <Typography
                      component="p"
                      variant="body1"
                      className="policy-breach-label"
                    >
                      RIDE DATETIME
                    </Typography>

                    <Typography
                      component="p"
                      variant="body1"
                      className="policy-breach-text"
                    >
                      {(policyBreach?.estimate as any)?.ride?.datetime || '-'}
                    </Typography>
                  </li>

                  <li className="policy-breach-field">
                    <Typography
                      component="p"
                      variant="body1"
                      className="policy-breach-label"
                    >
                      SELECTION MADE AT
                    </Typography>

                    <Typography
                      component="p"
                      variant="body1"
                      className="policy-breach-text"
                    >
                      {(policyBreach?.estimate as any)?.ride?.selection_made_at || '-'}
                    </Typography>
                  </li>
                </ul>
              </article>
            </section>
          }
        </>
        ,
        <>
          {isPolicyBreachLoading ?
            <div className="component-loader">
              <Svg src={ballTriangleLoader} />
            </div>
            : <section className="policy-breach-section shadow">
              <Typography
                component="p"
                variant="body1"
                className="policy-breach-text"
              >
                {(policyBreach?.estimate as any)?.ride?.justification || '-'}
              </Typography>
            </section>
          }
        </>
        ,
        <>
          {isPolicyBreachLoading ?
            <div className="component-loader">
              <Svg src={ballTriangleLoader} />
            </div>
            : <section className="policy-breach-section shadow">
              <article className="policy-breach-article">
                <ul className="policy-breach-fields">
                  <li className="policy-breach-field">
                    <Typography
                      component="p"
                      variant="body1"
                      className="policy-breach-label"
                    >
                      SERVICE
                    </Typography>

                    <Typography
                      component="p"
                      variant="body1"
                      className="policy-breach-text"
                    >
                      {(policyBreach?.estimate as any)?.service || '-'}
                    </Typography>
                  </li>

                  <li className="policy-breach-field">
                    <Typography
                      component="p"
                      variant="body1"
                      className="policy-breach-label"
                    >
                      DISTANCE (METERS)
                    </Typography>

                    <Typography
                      component="p"
                      variant="body1"
                      className="policy-breach-text"
                    >
                      {(policyBreach?.estimate as any)?.distance_in_meters || '-'}
                    </Typography>
                  </li>

                  <li className="policy-breach-field">
                    <Typography
                      component="p"
                      variant="body1"
                      className="policy-breach-label"
                    >
                      DURATION (MINUTES)
                    </Typography>

                    <Typography
                      component="p"
                      variant="body1"
                      className="policy-breach-text"
                    >
                      {
                        (policyBreach?.estimate as any)?.time_in_seconds
                          ? (policyBreach?.estimate as any)?.time_in_seconds / 60
                          : '-'
                      }
                    </Typography>
                  </li>

                  <li className="policy-breach-field">
                    <Typography
                      component="p"
                      variant="body1"
                      className="policy-breach-label"
                    >
                      MIN PRICE ($)
                    </Typography>

                    <Typography
                      component="p"
                      variant="body1"
                      className="policy-breach-text"
                    >
                      {
                        (policyBreach?.estimate as any)?.min_price_in_cents
                          ? (policyBreach?.estimate as any)?.min_price_in_cents / 100
                          : '-'
                      }
                    </Typography>
                  </li>

                  <li className="policy-breach-field">
                    <Typography
                      component="p"
                      variant="body1"
                      className="policy-breach-label"
                    >
                      MAX PRICE ($)
                    </Typography>

                    <Typography
                      component="p"
                      variant="body1"
                      className="policy-breach-text"
                    >
                      {
                        (policyBreach?.estimate as any)?.max_price_in_cents
                          ? (policyBreach?.estimate as any)?.max_price_in_cents / 100
                          : '-'
                      }
                    </Typography>
                  </li>

                  <li className="policy-breach-field">
                    <Typography
                      component="p"
                      variant="body1"
                      className="policy-breach-label"
                    >
                      AVERAGE PRICE ($)
                    </Typography>

                    <Typography
                      component="p"
                      variant="body1"
                      className="policy-breach-text"
                    >
                      {
                        (policyBreach?.estimate as any)?.avg_price_in_cents
                          ? (policyBreach?.estimate as any)?.avg_price_in_cents / 100
                          : '-'
                      }
                    </Typography>
                  </li>

                  <li className="policy-breach-field">
                    <Typography
                      component="p"
                      variant="body1"
                      className="policy-breach-label"
                    >
                      REAL TIME API
                    </Typography>

                    <Typography
                      component="p"
                      variant="body1"
                      className="policy-breach-text"
                    >
                      {
                        (policyBreach?.estimate as any)?.realtime
                          ? 'yes'
                          : 'no'
                      }
                    </Typography>
                  </li>
                </ul>
              </article>
            </section>
          }
        </>
        ,
        <>
          {isPolicyBreachLoading ?
            <div className="component-loader">
              <Svg src={ballTriangleLoader} />
            </div>
            : <section className="policy-breach-section shadow">
              <Typography
                component="p"
                variant="body1"
                className="policy-breach-text"
              >
                {
                  (policyBreach?.estimate as any)?.violation_warnings
                    ? (policyBreach?.estimate as any)?.violation_warnings.toString()
                    : '-'
                }
              </Typography>
            </section>
          }
        </>
        ,
        <>
          <div className="component-loader">
            <Svg src={ballTriangleLoader} />
          </div></>
      ]}
    />


  </PolicyBreachesDetailContainer>
}

export default PolicyBreachesDetail