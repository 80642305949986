import axios, { AxiosPromise } from 'axios'
import { getHeaders, SERVICE_URL } from '../../adapters/dime-api-adapter';
import { ProfileApiView } from '../../adapters/dime-api-client';

export interface IGetProfileRequestParams {
  search?: string;
  ordering?: string;
  limit?: string;
  offset?: string;
}

export const getProfileRequest = (
  token: string,
  params?: IGetProfileRequestParams,
): AxiosPromise<ProfileApiView> => {
  const endpoint = `${SERVICE_URL}/auth-api/profile/`;

  return axios({
    method: "GET",
    url: endpoint,
    headers: getHeaders(token),
    params
  });
};