import styled from "@emotion/styled"
import { colors } from "../../assets/colors"

 const MainHeaderStyle = styled.div`
  .main-header {
    border-bottom: 1px solid ${colors.lightGray};
    box-shadow: none;
    background: ${colors.white};
    color: ${colors.black};

    .logo {
      transition: 0.5s all ease-in-out;

      &.hide {
        transition: 0.5s all ease-in-out;
        display: none; 
      }
    }
  }

  .menu-icon {
    margin: 0;
  }
`

export default MainHeaderStyle