import { Typography } from "@mui/material"
import React from "react"
import CompanySubscriptionContainer from "./company-subscription-style"

interface CompanySubscriptionProps {
  service_plan: string;
  price_per_user: string;
  interval_type: string;
  intervals: string;
  current_period_start: string;
  current_period_end: string;
  status: string;
  next_cycle_total: string;
}

const CompanySubscription: React.FC<CompanySubscriptionProps> = (props: CompanySubscriptionProps) => {
  return <CompanySubscriptionContainer>
    <ul className="company-info-list">

      <li className="company-field">
        <Typography
          component="p"
          variant="body1"
          className="company-label"
        >
          SUBSCRIPTION PLAN
        </Typography>

        <Typography
          component="p"
          variant="body1"
          className="company-text"
        >
          {props.service_plan}
        </Typography>
      </li>

      <li className="company-field">
        <Typography
          component="p"
          variant="body1"
          className="company-label"
        >
          PRICE PER USER
        </Typography>

        <Typography
          component="p"
          variant="body1"
          className="company-text"
        >
          {props.price_per_user}
        </Typography>
      </li>

      <li >

      </li>

      <li className="company-field">
        <Typography
          component="p"
          variant="body1"
          className="company-label"
        >
          INTERVAL TYPE
        </Typography>

        <Typography
          component="p"
          variant="body1"
          className="company-text"
        >
          {props.interval_type}
        </Typography>
      </li>

      <li className="company-field">
        <Typography
          component="p"
          variant="body1"
          className="company-label"
        >
          INTERVALS
        </Typography>

        <Typography
          component="p"
          variant="body1"
          className="company-text"
        >
          {props.intervals}
        </Typography>
      </li>

      <li className="company-field">
        <Typography
          component="p"
          variant="body1"
          className="company-label"
        >
          CURRENT PERIOD START
        </Typography>

        <Typography
          component="p"
          variant="body1"
          className="company-text"
        >
          {props.current_period_start}
        </Typography>
      </li>

      <li className="company-field">
        <Typography
          component="p"
          variant="body1"
          className="company-label"
        >
          CURRENT PERIOD END
        </Typography>

        <Typography
          component="p"
          variant="body1"
          className="company-text"
        >
          {props.current_period_end}
        </Typography>
      </li>

      <li className="company-field">
        <Typography
          component="p"
          variant="body1"
          className="company-label"
        >
          STATUS
        </Typography>

        <Typography
          component="p"
          variant="body1"
          className="company-text"
        >
          {props.status}
        </Typography>
      </li>

      <li className="company-field">
        <Typography
          component="p"
          variant="body1"
          className="company-label"
        >
          NEXT CYCLE TOTAL
        </Typography>

        <Typography
          component="p"
          variant="body1"
          className="company-text"
        >
          {props.next_cycle_total}
        </Typography>
      </li>
    </ul>
  </CompanySubscriptionContainer>
}

export default CompanySubscription