import styled from "@emotion/styled";
import { colors } from "../../../assets/colors";

interface Props {
  colorIcon?: string; 
}
const CardNumberContainer = styled.div<Props>`
  background: ${colors.white};
  padding: 16px; 
  border-radius: 16px; 
  box-shadow: 2px 2px 15px 0 #e5e5e5;
  display: flex;
  align-items: flex-start; 
  justify-content: space-between; 

  .card-number-icon {
    width: calc(50% - 8px); 
    margin-right: 16px; 
    height: 100%;

    svg {
      width: 80px;
      height: 80px;

      path {
        fill: ${props => props.colorIcon || colors.black}
      }
    }
  }

  .card-number-content {

    .card-number-title {
      text-align: right; 
      color: ${colors.hardGray};
    }

    .card-number-number {
      text-align: right; 
      color: ${colors.black};
    }
  }
` 

export default CardNumberContainer;