import { Typography, TextField, Button, Checkbox, FormControlLabel } from '@mui/material'
import { DateTimePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import React, { useContext, useEffect, useState } from 'react'
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { IAppPolicy } from '../../../entities/policy-entity';
import useForm from '../../../hooks/useForm/use-form';
import { IValidation, IFormValidations, ISubmitUseFormParams } from '../../../hooks/useForm/use-form-types';
import { RequiredValidation } from '../../../hooks/useForm/use-form-validations';
import { StoreContext } from '../../../lib/store/store';
import { usePolicyService } from '../../../services/policy/policy-service';
import { useServiceService } from '../../../services/service/service-service';
import PoliciesEditContainer from './policies-edit-style'

interface IFormValues {
  name: string;
  force_most_economic_ride: boolean;
  from_time: string;
  to_time: string;
  max_monthly_expense: string;
  blacklisted_services: string;
}

const requiredValidation: IValidation = RequiredValidation();

const formValidations: IFormValidations<IFormValues> = {
  name: [requiredValidation],
  force_most_economic_ride: [],
  from_time: [requiredValidation],
  to_time: [requiredValidation],
  max_monthly_expense: [],
  blacklisted_services: [requiredValidation]
}

const getDateFromHour = (stringHour) => {
  let today = new Date()
  const minutes = stringHour.split(':')[1].trim()
  const hour = stringHour.split(':')[0].trim()

  today.setHours(hour, minutes)

  return today
}

const usePolicyData = () => {
  const policyService = usePolicyService()
  const pathParams = useParams()
  const [policy, setPolicy] = useState<IAppPolicy>()

  const { data: policyData, error, isLoading } = useQuery(
    ["policy"],
    () => policyService.getPolicyById(pathParams.id),
    { retry: false },
  )

  useEffect(() => {
    if (!policy) {
      setPolicy(policyData)
    }
  }, [policyData])

  return {
    policy
  }
}

const usePoliciesEditForm = () => {
  const policyService = usePolicyService()
  const store = useContext(StoreContext)

  const path = useParams();
  const { policy } = usePolicyData()

  const initialValues: IFormValues = {
    name: policy?.name || '',
    force_most_economic_ride: policy?.force_most_economic_ride || false,
    from_time: policy?.from_time || new Date().toISOString(),
    to_time: policy?.to_time || new Date().toISOString(),
    max_monthly_expense: policy?.max_monthly_expense,
    blacklisted_services: '',
  }

  const submit = async (params: ISubmitUseFormParams<IFormValues>) => {
    await policyService.updatePolicy(
      path.id,
      {
        ...params.values,
        blacklisted_services: params.values.blacklisted_services as any
      })

    store.setModalInfo({
      open: true,
      text: 'The policy has been updated successfully'
    })
  }

  const formActions = useForm<IFormValues>(
    {
      initialValues,
      formValidations,
      submit
    }
  )

  useEffect(() => {
    if (policy && !formActions.values.name) {
      const today = new Date()
      const timeDefault = today.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
      });

      formActions.setAGroupOfValues({
        name: policy?.name || '',
        force_most_economic_ride: policy?.force_most_economic_ride || false,
        from_time: policy?.from_time || timeDefault,
        to_time: policy?.to_time || timeDefault,
        max_monthly_expense: policy?.max_monthly_expense,
        blacklisted_services: '',
      })
    }
  }, [policy])

  const handleChangeBlacklistedService = (checked: boolean, label: number) => {

    let newBlacklistedService = formActions.values.blacklisted_services as any || []

    if (checked) {

      newBlacklistedService.push(label)
      formActions.handleFieldEvent(newBlacklistedService as any, "blacklisted_services")
    } else {
      const index = newBlacklistedService.indexOf(label)
      newBlacklistedService.splice(index, 1);
    }

  }

  return {
    ...formActions,
    handleChangeBlacklistedService
  }
}

const useServiceData = () => {
  const serviceService = useServiceService()

  const { data: services, error, isLoading } = useQuery(
    ["service"],
    () => serviceService.getServices(),
    { retry: false },
  )

  return {
    services,

  }
}

const usePolicieEditPage = () => {
  const formActions = usePoliciesEditForm()
  const { services } = useServiceData()

  return {
    ...formActions,
    services,
  }
}

const PoliciesEditPage: React.FC = () => {
  const actions = usePolicieEditPage()

  return <PoliciesEditContainer>
    <LocalizationProvider dateAdapter={AdapterDateFns}>

      <Typography
        component="h1"
        variant="h4"
        className="policies-edit-title"
      >
        Edit policy
      </Typography>

      <form
        className="policies-edit-form"
        id='policies-edit-form'
        onSubmit={(event) => {
          event.preventDefault();
          actions.onSubmit()
        }}
      >
        <fieldset className="policies-edit-fieldset">
          <Typography
            component="p"
            variant="h6"
            className="fieldset-title"
          >
            Policy basic information
          </Typography>

          <TextField
            className="policies-edit-field"
            fullWidth
            label="Name"
            value={actions.values.name}
            name="name-field"
            required
            error={actions.errorValues.name.errorText ? true : false}
            helperText={actions.errorValues.name.errorText || ''}
            onChange={(event) => actions.handleFieldEvent(event.target.value, "name")}
          />

          <div
            className="policies-edit-2-row"
          >
            <div
              className="policies-edit-field"
            >
              <TimePicker
                onChange={(event) => {
                  if (JSON.stringify(event)) {
                    const date = new Date(event)
                    const hour = date.toLocaleTimeString('en-US', {
                      hour: '2-digit',
                      minute: '2-digit',
                      hour12: false
                    });
                    actions.handleFieldEvent(hour, "from_time")
                  }
                }}
                value={getDateFromHour(actions.values.from_time)}
                label="From Time"
                renderInput={(params) => <TextField
                  {...params}
                  fullWidth
                  required
                  error={actions.errorValues.from_time.errorText ? true : false}
                  helperText={actions.errorValues.from_time.errorText || ''}
                />}
              />
            </div>


            <div
              className="policies-edit-field"
            >
              <TimePicker
                onChange={(event: Date) => {
                  if (JSON.stringify(event)) {
                    const date = new Date(event)
                    const hour = date.toLocaleTimeString('en-US', {
                      hour: '2-digit',
                      minute: '2-digit',
                      hour12: false
                    });
                    actions.handleFieldEvent(hour, "to_time")
                  }
                }
                }
                value={getDateFromHour(actions.values.to_time)}
                label="To Time"
                renderInput={(params) => <TextField
                  {...params}
                  fullWidth
                  error={actions.errorValues.to_time.errorText ? true : false}
                  helperText={actions.errorValues.to_time.errorText || ''}
                />}
              />
            </div>


          </div>

          <div
            className="policies-edit-2-row"
          >

            <TextField
              className="policies-edit-field"
              type="number"
              label="Max monthly expense"
              name="max-monthly-expense-field"
              value={actions.values.max_monthly_expense}
              error={actions.errorValues.max_monthly_expense.errorText ? true : false}
              helperText={actions.errorValues.max_monthly_expense.errorText || ''}
              onChange={(event) => actions.handleFieldEvent(event.target.value, "max_monthly_expense")}
            />
            <div></div>
          </div>

          <FormControlLabel
            className="policies-edit-field"
            label="Force most economic ride"
            control={<Checkbox
              color="primary"
              onChange={(event) => actions.handleFieldEvent(event.target.checked as any, "force_most_economic_ride")}
              value={actions.values.force_most_economic_ride}

              inputProps={{
                'aria-label': 'select all desserts',
              }}
            />}
          />
        </fieldset>


        <fieldset className="policies-edit-fieldset black-listed-field">
          <Typography
            component="p"
            variant="h6"
            className="black-listed-title"
          >
            Blacklisted services
          </Typography>

          <ul className="policy-services">
            {actions.services && actions.services.map((service, index) => <li
              className='policy-service'
            >
              <FormControlLabel
                label={service.name}
                control={<Checkbox
                  color="primary"
                  onChange={(event) => actions.handleChangeBlacklistedService(event.target.checked, service.pk)}
                  inputProps={{
                    'aria-label': 'select all desserts',
                  }}
                />}
              />


            </li>)}
          </ul>
        </fieldset>
      </form>

      <Button
        className="policies-edit-button"
        type="submit"
        fullWidth
        variant="contained"
        color="success"
        form="policies-edit-form"
      >
        Edit
      </Button>
    </LocalizationProvider>

  </PoliciesEditContainer>
}

export default PoliciesEditPage