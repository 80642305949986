import axios, { AxiosPromise } from 'axios'
import { getHeaders, SERVICE_URL } from '../../adapters/dime-api-adapter';
import { User } from '../../adapters/dime-api-client';

export const deleteUserRequest = (
  token: string,
  id: string,
): AxiosPromise<User> => {
  const endpoint = `${SERVICE_URL}/dashboard-api/users/${id}/delete/`;

  return axios({
    method: "DELETE",
    url: endpoint,
    headers: getHeaders(token),
  });
};