import React, { useContext, useEffect } from 'react'
import { useQuery } from 'react-query'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { colors } from '../../../assets/colors'
import PrivateRoutes from '../../../containers/routes/private-routes/private-routes'
import PublicRoutes from '../../../containers/routes/public-routes/public-routes'
import { StoreContext } from '../../../lib/store/store'
import HomePage from '../../../pages/home/home'
import LoginPage from '../../../pages/login/login'
import { useAuthService } from '../../../services/auth/auth-service'
import MainLayout from '../main-layout/main-layout'

const useAuth = () => {
  const authService = useAuthService()

  const { data: profile, error, isLoading } = useQuery(
    ["user"],
    authService.getProfile,
    { retry: false },
  )

  const store = useContext(StoreContext)

  useEffect(() => {
    if (profile) {
      console.log(profile)
      store.setProfile(profile)
    }
  }, [profile])

  return {
    profile: {
      error,
      data: profile,
      isLoading
    },

    authService
  }
}

const LayoutAuth: React.FC = () => {
  const { profile, authService } = useAuth()

  const isPublic = !authService.hasToken() || profile.error;

  return isPublic ?
    <PublicRoutes /> : profile.isLoading ?
      <div style={{ background: colors.white }}>
      </div>
      : <PrivateRoutes />
}

export default LayoutAuth