import styled from '@emotion/styled'

const PoliciesCreateContainer = styled.div`
  .policies-create-title {
    margin-bottom: 24px;
  }

  .policies-create-form {
    width: 100%;
    max-width: 600px;
    margin-bottom: 24px;

    .policies-create-fieldset {
      margin-bottom: 24px;

      .fieldset-title {
        margin-bottom: 8px; 
      }
    }

    .policies-create-field {
      margin-bottom: 16px;
    }

    .policies-create-2-row {
      display: flex;
      align-items: center; 
      justify-content: space-between; 
      margin-bottom: 16px; 
      
      .policies-create-field {
        margin-bottom: 0;
        margin-right: 16px; 
        width: calc((100% - 16px) / 2);
       
        &:last-child {
          margin-right: 0; 
        }
      }
    }

    .black-listed-title {
      margin-bottom: 8px; 
    }

    .policy-services{
      width: 100%;
      display: grid; 
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 8px 16px;
    }
  }

  .policies-create-button {
    width: 100px;
  }
`

export default PoliciesCreateContainer