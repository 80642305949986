import { Button, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import React, { useContext } from 'react'
import { useParams } from 'react-router-dom';
import useForm from '../../../hooks/useForm/use-form';
import { IValidation, IFormValidations, ISubmitUseFormParams } from '../../../hooks/useForm/use-form-types';
import { RequiredValidation } from '../../../hooks/useForm/use-form-validations';
import { StoreContext } from '../../../lib/store/store';
import { useUserService } from '../../../services/user/user-service';
import ChangeUserPasswordContainer from './change-password-style';


interface IFormValues {
  password: string;
  repeatedPassword: string;
}

const requiredValidation: IValidation = RequiredValidation();

const formValidations: IFormValidations<IFormValues> = {
  password: [requiredValidation],
  repeatedPassword: [],
}

const useChangeUserPasswordForm = () => {
  const userService = useUserService()
  const store = useContext(StoreContext)
  const pathParams = useParams()

  const initialValues: IFormValues = {
    password: '',
    repeatedPassword: '',

  }

  const submit = async (params: ISubmitUseFormParams<IFormValues>) => {

    if (params.values.password === params.values.repeatedPassword) {

      await userService.updateUserPassword(
        pathParams.id,
        {
          password: params.values.password
        }
      )

      store.setModalInfo({
        open: true,
        text: 'The user password has been changed successfully'
      })
    } else {
      store.setModalError({
        open: true,
        text: 'The passwords have to be the same'
      })
    }
  }

  const formActions = useForm<IFormValues>(
    {
      initialValues,
      formValidations,
      submit
    }
  )

  return {
    ...formActions,
  }
}

const ChangeUserPasswordPage: React.FC = () => {
  const actions = useChangeUserPasswordForm()

  return <ChangeUserPasswordContainer>
    <Typography
      component="h1"
      variant="h4"
      className="change-user-password-title"
    >
      Change user password
    </Typography>

    <form
      className="change-user-password-form"
      id='change-user-password-form'
      onSubmit={(event) => {
        event.preventDefault();
        actions.onSubmit()
      }}
    >

      <div className="change-user-password-field">
        <TextField
          className="password-field"
          required
          fullWidth
          name="password"
          label="New Password"
          type="password"
          autoComplete="current-password"
          autoFocus
          error={actions.errorValues.password.errorText ? true : false}
          helperText={actions.errorValues.password.errorText || ''}
          onChange={(event) => actions.handleFieldEvent(event.target.value, "password")}
        />
        <ul className="change-user-password-label">
          <li className="change-user-password-recomend">
            Your password can’t be too similar to your other personal information.
          </li>
          <li className="change-user-password-recomend">
            Your password must contain at least 8 characters.
          </li>
          <li className="change-user-password-recomend">
            Your password can’t be a commonly used password.
          </li>
          <li className="change-user-password-recomend">
            Your password can’t be entirely numeric.
          </li>
        </ul>
      </div>


      <TextField
        className="change-user-field"
        required
        fullWidth
        name="password"
        label="Repeat new password"
        type="password"
        autoComplete="current-password"
        autoFocus
        error={actions.errorValues.repeatedPassword.errorText ? true : false}
        helperText={actions.errorValues.repeatedPassword.errorText || ''}
        onChange={(event) => actions.handleFieldEvent(event.target.value, "repeatedPassword")}
      />
    </form>

    <Button
      className="change-user-password-button"
      type="submit"
      fullWidth
      variant="contained"
      color="success"
      form="change-user-password-form"
    >
      Change password
    </Button>

  </ChangeUserPasswordContainer>
}

export default ChangeUserPasswordPage