import { useContext } from "react";
import { getMonitoringRidesRequest, IGetMonitoringRidesRequestParams } from "../../lib/dime-api/requests/monitoring/monitoring-rides-request";
import { getMonitoringSummaryRequest, IGetMonitoringSummaryRequestParams } from "../../lib/dime-api/requests/monitoring/monitoring-summary-request";
import { IGetMonitoringUsersRequestParams, getMonitoringUsersRequest } from "../../lib/dime-api/requests/monitoring/monitoring-users-request";
import { LocalStorageConnector } from '../../lib/localstorage/localstorage'
import { StoreContext } from "../../lib/store/store";

export const useMonitoringService = () => {
  const store = useContext(StoreContext)

  const getMonitoringSummary = async (params?: IGetMonitoringSummaryRequestParams) => {
    try {

      const token = LocalStorageConnector.getElement('token');

      const response = await getMonitoringSummaryRequest(token, params || {})
      return response.data
    } catch (error) {
      console.log('getMonitoringSummary error', error)
      throw error
    }
  }

  const getMonitoringRides = async (params?: IGetMonitoringRidesRequestParams) => {
    try {

      const token = LocalStorageConnector.getElement('token');

      const response = await getMonitoringRidesRequest(token, params || {})

      return response.data.results
    } catch (error) {
      console.log('getMonitoringRides error', error)
      throw error
    }
  }

  const getMonitoringUsers = async (params?: IGetMonitoringUsersRequestParams) => {
    try {

      const token = LocalStorageConnector.getElement('token');

      const response = await getMonitoringUsersRequest(token, params || {})

      return response.data.results
    } catch (error) {
      console.log('getMonitoringUsers error', error)
      throw error
    }
  }

  return {
    getMonitoringSummary,
    getMonitoringRides,
    getMonitoringUsers
  }

}