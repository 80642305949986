import { Button, Checkbox, FormControlLabel, TextField, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { useQuery } from 'react-query';
import { IAppService } from '../../../entities/service-entity';
import useForm from '../../../hooks/useForm/use-form';
import { IFormValidations, ISubmitUseFormParams, IValidation } from '../../../hooks/useForm/use-form-types';
import { RequiredValidation } from '../../../hooks/useForm/use-form-validations';
import { usePolicyService } from '../../../services/policy/policy-service';
import { useServiceService } from '../../../services/service/service-service';
import PoliciesCreateContainer from './policies-create-style'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useParams } from 'react-router-dom';
import { IAppPolicy } from '../../../entities/policy-entity';
import { StoreContext } from '../../../lib/store/store';

interface IFormValues {
  name: string;
  force_most_economic_ride: boolean;
  from_time: string;
  to_time: string;
  max_monthly_expense: string;
  blacklisted_services: string;
}

const requiredValidation: IValidation = RequiredValidation();

const formValidations: IFormValidations<IFormValues> = {
  name: [requiredValidation],
  force_most_economic_ride: [requiredValidation],
  from_time: [requiredValidation],
  to_time: [requiredValidation],
  max_monthly_expense: [requiredValidation],
  blacklisted_services: [requiredValidation]
}

const getDateFromHour = (stringHour) => {
  let today = new Date()
  const minutes = stringHour.split(':')[1].trim()
  const hour = stringHour.split(':')[0].trim()

  today.setHours(hour, minutes)

  return today
}

const usePoliciesCreateForm = () => {
  const policyService = usePolicyService()
  const store = useContext(StoreContext)

  const initialValues: IFormValues = {
    name: '',
    force_most_economic_ride: false,
    from_time: new Date().toISOString(),
    to_time: new Date().toISOString(),
    max_monthly_expense: '',
    blacklisted_services: '',
  }

  const submit = async (params: ISubmitUseFormParams<IFormValues>) => {
    await policyService.createPolicy({
      ...params.values,
      blacklisted_services: params.values.blacklisted_services as any
    })

    store.setModalInfo({
      open: true,
      text: 'The policy has been created successfully'
    })


  }

  const formActions = useForm<IFormValues>(
    {
      initialValues,
      formValidations,
      submit
    }
  )

  const handleChangeBlacklistedService = (checked: boolean, label: number) => {

    let newBlacklistedService = formActions.values.blacklisted_services as any || []

    if (checked) {

      newBlacklistedService.push(label)
      formActions.handleFieldEvent(newBlacklistedService as any, "blacklisted_services")
    } else {
      const index = newBlacklistedService.indexOf(label)
      newBlacklistedService.splice(index, 1);
    }

  }

  return {
    ...formActions,
    handleChangeBlacklistedService
  }
}

const useServiceData = () => {
  const serviceService = useServiceService()

  const { data: services, error, isLoading } = useQuery(
    ["service"],
    () => serviceService.getServices(),
    { retry: false },
  )

  return {
    services,

  }
}

const usePoliciesCreatePage = () => {
  const formActions = usePoliciesCreateForm()
  const { services } = useServiceData()

  return {
    ...formActions,
    services,
  }
}

const PoliciesCreatePage: React.FC = () => {
  const actions = usePoliciesCreatePage()

  return <PoliciesCreateContainer>
    <LocalizationProvider dateAdapter={AdapterDateFns}>

      <Typography
        component="h1"
        variant="h4"
        className="policies-create-title"
      >
        Add new policy
      </Typography>

      <form
        className="policies-create-form"
        id='policies-create-form'
        onSubmit={(event) => {
          event.preventDefault();
          actions.onSubmit()
        }}
      >
        <fieldset className="policies-create-fieldset">
          <Typography
            component="p"
            variant="h6"
            className="fieldset-title"
          >
            Policy basic information
          </Typography>

          <TextField
            className="policies-create-field"
            fullWidth
            label="Name"
            name="name-field"
            autoFocus
            required
            error={actions.errorValues.name.errorText ? true : false}
            helperText={actions.errorValues.name.errorText || ''}
            onChange={(event) => actions.handleFieldEvent(event.target.value, "name")}
          />

          <div
            className="policies-create-2-row"
          >
            <div
              className="policies-create-field"
            >
              <TimePicker
                onChange={(event) => {
                  if (JSON.stringify(event)) {
                    const date = new Date(event)
                    const hour = date.toLocaleTimeString('en-US', {
                      hour: '2-digit',
                      minute: '2-digit',
                      hour12: false
                    });
                    actions.handleFieldEvent(hour, "from_time")
                  }
                }}
                value={getDateFromHour(actions.values.from_time)}
                label="From Time"
                renderInput={(params) => <TextField
                  {...params}
                  fullWidth
                  required

                  error={actions.errorValues.from_time.errorText ? true : false}
                  helperText={actions.errorValues.from_time.errorText || ''}
                />}
              />
            </div>

            <div
              className="policies-create-field"
            >
              <TimePicker
                onChange={(event: Date) => {
                  if (JSON.stringify(event)) {
                    const date = new Date(event)
                    const hour = date.toLocaleTimeString('en-US', {
                      hour: '2-digit',
                      minute: '2-digit',
                      hour12: false
                    });
                    actions.handleFieldEvent(hour, "to_time")
                  }
                }
                }
                value={getDateFromHour(actions.values.to_time)}
                label="To Time"
                renderInput={(params) => <TextField
                  {...params}
                  fullWidth
                  error={actions.errorValues.to_time.errorText ? true : false}
                  helperText={actions.errorValues.to_time.errorText || ''}
                />}
              />
            </div>


          </div>

          <div
            className="policies-create-2-row"
          >

            <TextField
              className="policies-create-field"
              required
              type="number"
              label="Max monthly expense"
              name="max-monthly-expense-field"
              autoFocus
              error={actions.errorValues.max_monthly_expense.errorText ? true : false}
              helperText={actions.errorValues.max_monthly_expense.errorText || ''}
              onChange={(event) => actions.handleFieldEvent(event.target.value, "max_monthly_expense")}
            />
            <div></div>
          </div>

          <FormControlLabel
            className="policies-create-field"
            label="Force most economic ride"
            control={<Checkbox
              color="primary"
              onChange={(event) => actions.handleFieldEvent(event.target.checked as any, "force_most_economic_ride")}

              inputProps={{
                'aria-label': 'select all desserts',
              }}
            />}
          />
        </fieldset>


        <fieldset className="policies-create-fieldset black-listed-field">
          <Typography
            component="p"
            variant="h6"
            className="black-listed-title"
          >
            Blacklisted services
          </Typography>

          <ul className="policy-services">
            {actions.services && actions.services.map((service, index) => <li
              className='policy-service'
            >
              <FormControlLabel
                label={service.name}
                control={<Checkbox
                  color="primary"
                  onChange={(event) => actions.handleChangeBlacklistedService(event.target.checked, service.pk)}
                  inputProps={{
                    'aria-label': 'select all desserts',
                  }}
                />}
              />


            </li>)}
          </ul>
        </fieldset>
      </form>

      <Button
        className="policies-create-button"
        type="submit"
        fullWidth
        variant="contained"
        color="success"
        form="policies-create-form"
      >
        Create
      </Button>
    </LocalizationProvider>

  </PoliciesCreateContainer>
}

export default PoliciesCreatePage