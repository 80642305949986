import { AxiosPromise } from "axios";
import { getHeaders, SERVICE_URL } from "../../adapters/dime-api-adapter";
import { Policy } from "../../adapters/dime-api-client";
import axios from 'axios'

export const deletePolicyRequest = (
  token: string,
  id: string,
): AxiosPromise<Policy> => {
  const endpoint = `${SERVICE_URL}/dashboard-api/policies/${id}/delete/`;

  return axios({
    method: "DELETE",
    url: endpoint,
    headers: getHeaders(token),
  });
};