import styled from "@emotion/styled"
import { colors } from "../../../assets/colors"

const CompanySubscriptionContainer = styled.div`
  .company-subscription-list {
    display: grid; 
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 24px;

    .company-subscription-field {
      .company-subscription-label {
        font-weight: 600;
        margin-bottom: 4px;
        color: ${colors.hardGray};
      
        .company-subscription-text {
          color: ${colors.black};
        }
      }
    }  
  }
`

export default CompanySubscriptionContainer