export const routes = {
  home: '/',
  login: '/login',
  forgotPassword: '/forgot-password',
  monitoring: '/monitoring',
  company: '/company',
  paymentMethodAdd: '/payment-method/add',
  paymentMethodEdit: (id: string) => `/payment-method/${id}/edit`,
  policies: '/policies',
  policyCreate:`/policies/create`,
  policyEdit: (id: string) => `/policies/${id}/edit`,
  policyBreaches: '/policy-breaches',
  policyBreachDetail:  (id: string) => `/policy-breaches/${id}`,
  users: '/users',
  userCreate: '/users/create',
  userUpdate: (id: string) => `/users/${id}/edit`,
  changeUserPassword: (id: string) => `/users/${id}/change-password`,

}