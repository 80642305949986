import styled from '@emotion/styled'

const PaymentMethodAddFormContainer = styled.form`

  .field {
    margin-bottom: 24px; 
    border: 1px solid black; 
    border-radius: 4px; 
    padding: 12px; 
  }
`
export default PaymentMethodAddFormContainer