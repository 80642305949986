import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { ResetPasswordRequest } from '../../lib/dime-api/adapters/dime-api-client'
import { getProfileRequest } from '../../lib/dime-api/requests/auth/get-profile-request'
import { loginUserRequest } from '../../lib/dime-api/requests/auth/login-user-request'
import { logoutUserRequest } from '../../lib/dime-api/requests/auth/logout-user-request'
import { requestResetPasswordRequest } from '../../lib/dime-api/requests/auth/request-reset-password-request'
import { resetPasswordRequest } from '../../lib/dime-api/requests/auth/reset-password-request'
import { LocalStorageConnector } from '../../lib/localstorage/localstorage'
import { StoreContext } from '../../lib/store/store'
import { ILoginUserParams, IRequestRecoverPasswordParams } from './auth-service-types'

const DEFAULT_ERROR_TEXT = 'Ha ocurrido un error'

export const useAuthService = () => {
  const store = useContext(StoreContext)

  const loginUser = async ({
    email,
    password
  }: ILoginUserParams) => {
    try {
      store.setLoading(true)
      const response = await loginUserRequest({
        email,
        password
      })
      const { user, token } = response.data

      LocalStorageConnector.setElement('token', token);
      store.setLoading(false)

      return user
    } catch (error) {
      if (error?.response?.data?.code === "ValidationError") {
        const errorText = 'The credentials are incorrect'
        store.setModalError({
          open: true,
          text: errorText
        })
      } else {
        store.setModalError({
          open: true,
          text: DEFAULT_ERROR_TEXT
        })
      }

      store.setLoading(false)
      console.log(error)
      throw error
    }
  }

  const hasToken = () => {
    return LocalStorageConnector.getElement('token');
  }

  const getProfile = async () => {
    try {
      // store.setLoading(true)

      const token = LocalStorageConnector.getElement('token');

      const response = await getProfileRequest(token)
      // store.setLoading(false)

      return response.data
    } catch (error) {
      // store.setLoading(false)
      LocalStorageConnector.clearElement('token');

      throw error
    }
  }

  const logout = async () => {
    try {
      store.setLoading(true)

      const token = LocalStorageConnector.getElement('token');

      await logoutUserRequest(token)

      LocalStorageConnector.clearElement('token');
      store.setLoading(false)

    } catch (error) {
      store.setModalError({
        open: true,
        text: DEFAULT_ERROR_TEXT
      })
      store.setLoading(false)

      console.log(error)
      throw error
    }
  }

  const requestRecoverPassword = async ({ email }: IRequestRecoverPasswordParams) => {
    try {
      store.setLoading(true)

      await requestResetPasswordRequest({ email })
      store.setLoading(false)

    } catch (error) {
      store.setLoading(false)

      throw error
    }
  }

  const resetPassword = async (params: ResetPasswordRequest) => {
    try {
      store.setLoading(true)

      await resetPasswordRequest(params)
      store.setLoading(false)

    } catch (error) {
      store.setLoading(false)

      throw error
    }
  }


  return {
    loginUser,
    getProfile,
    logout,
    requestRecoverPassword,
    hasToken,
    resetPassword
  }
}