import axios, { AxiosPromise } from 'axios'
import { getHeaders, IDataList, SERVICE_URL } from '../../adapters/dime-api-adapter';
import { DashboardApiPolicyListViewOutput } from '../../adapters/dime-api-client';

export interface IGetPolicyViolationsRequestParams {
  search?: string;
  ordering?: string;
  limit?: number;
  offset?: number; 
}

export const getPolicyViolationsRequest = (
  token: string,
  params?: IGetPolicyViolationsRequestParams,
): AxiosPromise<IDataList<DashboardApiPolicyListViewOutput>> => {
  const endpoint = `${SERVICE_URL}/dashboard-api/policy-violations/list/`;

  return axios({
    method: "GET",
    url: endpoint,
    headers: getHeaders(token),
    params
  });
};