import { Typography, Button } from '@mui/material';
import React from 'react'
import { THeadings } from '../../../utils/types/layout-types';
import ModalConfirmContainer from './modal-confirm-style';
import ErrorIcon from '@mui/icons-material/Error';

interface ModalConfirmProps {
  title: {
    text: string;
    tag: THeadings;
  };
  description: string;
  modal: {
    ariaLabelledBy?: string;
    ariaDescribedBy?: string;
    ariaModal: boolean;
    className?: string;
  }
  events: {
    onAccept: () => void;
    onClose: () => void;
  }
  maxWidth?: string;
}


const ModalConfirm: React.FC<ModalConfirmProps> = (props: ModalConfirmProps) => {
  const {
    modal,
    title,
    description,
    maxWidth,
    events
  } = props;

  return <ModalConfirmContainer
    role="dialog"
    aria-modal={modal.ariaModal || false}
    aria-labelledby={modal.ariaLabelledBy || undefined}
    aria-describedby={modal.ariaDescribedBy || undefined}
    maxWidth={maxWidth}

  >
    <div className="modal-confirm-top">
      <div className="modal-confirm-icon">
        <ErrorIcon />
      </div>
      <Typography
        component={title.tag}
        variant="h4"
        className="modal-confirm-title"
      >
        {title.text}
      </Typography>

    </div>

    <Typography
      component="p"
      variant="body1"
      className="modal-confirm-text"
    >
      {description}
    </Typography>

    <div className="modal-confirm-buttons" >
      <Button
        className="modal-confirm-button"
        type="submit"
        fullWidth
        variant="contained"
        color="success"
        onClick={() => events.onAccept()}
      >
        Yes
      </Button>
      <Button
        className="modal-confirm-button"
        type="submit"
        fullWidth
        color={"background" as any}
        variant="contained"
        onClick={() => events.onClose()}
      >
        No
      </Button>
    </div>
  </ModalConfirmContainer>
}

export default ModalConfirm;