import styled from "@emotion/styled";
import { colors } from "../../assets/colors";

const MainTabsContainer = styled.div`

  .main-tabs-top {
    border-bottom: 1px solid ${colors.lightGray};
    
    .main-tabs-tab {
      color: ${colors.gray};

      &.Mui-selected {
        color: ${colors.hardGreen};
      }

    }

    .MuiTabs-indicator {
      background: ${colors.hardGreen};
    }
  }

  .main-tabs-panel {
    padding-top: 24px;
  }
`

export default MainTabsContainer