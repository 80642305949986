import styled from "@emotion/styled"
import { colors } from "../../../assets/colors"

const CompanyDetailContainer = styled.div`
  .company-detail-list {
    display: grid; 
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 24px;

    .company-detail-field {
      .company-detail-label {
        font-weight: 600;
        margin-bottom: 4px;
        color: ${colors.hardGray};
      
        .company-detail-text {
          color: ${colors.black};
        }
      }
    }  
  }
`

export default CompanyDetailContainer