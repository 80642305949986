import axios, { AxiosPromise } from 'axios'
import { getHeaders, SERVICE_URL } from '../../adapters/dime-api-adapter';
import { Policy } from '../../adapters/dime-api-client';

export interface IGetPoliciesRequestParams {
  search?: string;
  ordering?: string;
  limit?: number;
  offset?: number; 
}

export const getPoliciesRequest = (
  token: string,
  params?: IGetPoliciesRequestParams,
): AxiosPromise<any> => {
  const endpoint = `${SERVICE_URL}/dashboard-api/policies/list/`;

  return axios({
    method: "GET",
    url: endpoint,
    headers: getHeaders(token),
    params
  });
};