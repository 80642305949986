import styled from '@emotion/styled'
import { colors } from '../../../assets/colors'

const TableCompanySubscriptionsContainer = styled.div`
  box-shadow: 2px 2px 15px 0 #e5e5e5;
  border-radius: 8px; 
  padding: 0 8px; 
  padding-bottom: 0; 

  .row-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end; 
    
    .row-action {
      cursor: pointer; 
      width: 16px; 
      height: 16px; 
      min-width: 16px; 
      min-height: 16px; 
      max-width: 16px; 
      max-height: 16px; 
      margin-right: 16px; 

      &.edit-icon {
        svg {
          path{
            fill: ${colors.lightBlue};
          }
        }
      }

      &.duplicate-icon {
        svg {
          path{
            fill: ${colors.purple};
          }
        }
      }

      &.delete-icon {
        svg {
          path{
            fill: ${colors.red};
          }
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

`

export default TableCompanySubscriptionsContainer