import styled from "@emotion/styled"
import { colors } from "../../../assets/colors";

interface Props {
  maxWidth: string; 
}

const ModalErrorContainer = styled.div<Props>`
  background: ${colors.white};
  padding: 24px;
  max-width: 500px;
  width: 100%;
  border-radius: 8px;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.22);
  
  .modal-error-top {
    margin-bottom: 24px;
    display: flex; 
    align-items: center; 

    .modal-error-icon {
      width: 40px; 
      height: 40px; 
      margin-right: 16px;
      
      svg {
        width: 40px; 
        height: 40px; 

        path {
          fill: ${colors.red};
        }
      }
    }
  }

  .modal-error-text {
    margin-bottom: 32px;
  }

  .modal-error-button-container {
    width: 100%;
    max-width: 150px;
    margin: 0 auto;
  }
`

export default ModalErrorContainer