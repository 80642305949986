import { TableContainer, Table, TableHead, TableRow, TableCell, Button, TableSortLabel, TableBody, TablePagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import TablePolicyBreachesContainer from "./table-policy-breaches-style";

import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { IAppPolicyBreachList } from "../../../entities/policy-breach-entity";

interface ITableKeys {
  name: string;
  id: string;
  options?: {
    sort?: boolean
  }
}

const POLICY_BREACH_TABLE_KEYS: ITableKeys[] = [
  {
    name: 'Email',
    id: 'email',
  },
  {
    name: 'Policy name',
    id: 'name',
  },
  {
    name: 'Type',
    id: 'type',
  },
  {
    name: 'Ride datetime',
    id: 'rideDatetime',
  },
  {
    name: '',
    id: 'actions',
  }
]


interface TablePolicyBreachesProps {
  policyBreaches: IAppPolicyBreachList[]
  options?: {
    pageLength?: number
  }
  events?: {
    onClickRow?: (id: string) => void,
  }
}

const formatTableDate = (isoStringDate) => {
  const date = new Date(isoStringDate);
  const options = {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false
  }
  return date.toLocaleDateString('en-EN', options as any);
}

const usePagination = (props: TablePolicyBreachesProps) => {
  const { options, policyBreaches } = props
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(options?.pageLength || policyBreaches.length);

  useEffect(() => {
    console.log('cambio', policyBreaches)
  }, [policyBreaches])

  const getPaginatedRows = () => {
    if (options?.pageLength) {
      return policyBreaches.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    }


    return policyBreaches
  }

  return {
    page,
    setPage,
    rowsPerPage,
    getPaginatedRows
  }

}
const TablePolicyBreaches: React.FC<TablePolicyBreachesProps> = (props: TablePolicyBreachesProps) => {
  const { policyBreaches, events, options } = props;
  const {
    page,
    setPage,
    rowsPerPage,
    getPaginatedRows
  } = usePagination(props)

  return <TablePolicyBreachesContainer>
    <TableContainer>
      <Table
        sx={{ minWidth: 750 }}
        aria-labelledby="table-policies"
      >
        <TableHead>
          <TableRow>
            {POLICY_BREACH_TABLE_KEYS.map((tableKey) =>
              <TableCell
                key={tableKey.id}
                align={tableKey.id === 'actions' ? 'right' : 'left'}
                sortDirection={'desc'}
              >
                {
                  tableKey.options?.sort
                    ? < TableSortLabel
                      active={true}
                      direction={'desc'}
                      onClick={() => { }}
                    >
                      {tableKey.name}
                    </TableSortLabel>
                    : <>{tableKey.name}</>
                }
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {policyBreaches.length > 0 &&
            getPaginatedRows()
              .map((policyBreach, index) =>
                <TableRow
                  hover
                  onClick={(event) => events.onClickRow(String(policyBreach.id))}
                  role="checkbox"
                  tabIndex={-1}
                  key={index}
                >
                  <TableCell
                    component="th"
                    id={policyBreach.user.email}
                    scope="row"
                  >
                    {policyBreach.user.email}
                  </TableCell>

                  <TableCell
                    align="left"
                  >
                    {policyBreach.policy.name || 'Default'}
                  </TableCell>
                  <TableCell
                    align="left"
                  >
                    {policyBreach.type || '-'}
                  </TableCell>
                  <TableCell
                    align="left"
                  >
                    {(policyBreach?.estimate as any)?.ride?.datetime
                      ? formatTableDate((policyBreach?.estimate as any)?.ride?.datetime)
                      : '-'
                    }
                  </TableCell>
                  <TableCell
                    align="left"
                  >
                    <div
                      className="detail-icon row-action"
                      data-tip
                      data-for="detail-tooltip"
                    >
                      <RemoveRedEyeIcon />
                      <ReactTooltip
                        id="detail-tooltip"
                        backgroundColor="black"
                      >
                        See detail
                      </ReactTooltip>
                    </div>
                  </TableCell>
                </TableRow>
              )}
        </TableBody>
      </Table>
    </TableContainer>

    {options?.pageLength && <TablePagination
      rowsPerPageOptions={[options.pageLength]}
      component="div"
      count={policyBreaches.length}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={(event, newPage) => setPage(newPage)}
    />}
  </TablePolicyBreachesContainer >
}

export default TablePolicyBreaches