import { useState } from "react"
import { ProfileApiView } from "../../dime-api/adapters/dime-api-client"

export interface IProfileContext extends ProfileApiView {
}

export const useProfileContext = () => {
  const [profile, setProfile] = useState<IProfileContext>()

  return {
    profile,
    setProfile
  }

}