import axios, { AxiosPromise } from "axios";
import { getHeaders, SERVICE_URL } from "../../adapters/dime-api-adapter";
import { DashboardApiMonitoringSummaryOutput } from "../../adapters/dime-api-client";

export interface IGetMonitoringSummaryRequestParams {
  from_date?: string;
  to_date?: string;
}

export const getMonitoringSummaryRequest = (
  token: string,
  params?: IGetMonitoringSummaryRequestParams,
): AxiosPromise<DashboardApiMonitoringSummaryOutput> => {
  const endpoint = `${SERVICE_URL}/dashboard-api/monitoring/summary/`;

  return axios({
    method: "GET",
    url: endpoint,
    headers: getHeaders(token),
    params
  });
};