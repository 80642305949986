import React from 'react'
import MainLoaderContainer from './main-loader-style'
import loaderIcon from '../../assets/icons/files/main-loader.svg'
import Svg from '../svg/svg'

const MainLoader: React.FC = () => {
  return <MainLoaderContainer>
    <div className="main-loader-icon">
      <Svg src={loaderIcon} />
    </div>
  </MainLoaderContainer>
}

export default MainLoader