import React, { useState } from 'react'
import ReactTooltip from "react-tooltip";
import { TableContainer, Table, TableHead, TableRow, TableCell, Button, TableSortLabel, TableBody, TablePagination } from '@mui/material';
import TableUsersContainer from './table-users-style';
import { IAppUser } from '../../../entities/user-entity';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ControlPointDuplicateIcon from '@mui/icons-material/ControlPointDuplicate';

interface ITableKeys {
  name: string;
  id: string;
  options?: {
    sort?: boolean
  }
}

const USER_TABLE_KEYS: ITableKeys[] = [
  {
    name: 'Email',
    id: 'email',
  },
  {
    name: 'First name',
    id: 'firstName',
  },
  {
    name: 'Last name',
    id: 'lastName',
  },
  {
    name: 'Is company representative',
    id: 'isCompanyRepresentative',
  },
  {
    name: 'Active',
    id: 'active',
  },
  {
    name: 'Policy name',
    id: 'policyName',
  },
  {
    name: ' ',
    id: 'actions'
  }

]


interface TableUsersProps {
  users: IAppUser[]
  options?: {
    pageLength?: number
  }
  events?: {
    onAddRow?: () => void,
    onEditRow?: (user: IAppUser) => void,
    onChangePassword?: (user: IAppUser) => void,
    onDeleteRow?: (user: IAppUser) => void
  }
}

const usePagination = (props: TableUsersProps) => {
  const { options, users } = props
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(options?.pageLength || users.length);

  const getPaginatedRows = () => {
    if (options?.pageLength) {

      return users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    }

    return users
  }

  return {
    page,
    setPage,
    rowsPerPage,
    getPaginatedRows
  }

}
const TableUsers: React.FC<TableUsersProps> = (props: TableUsersProps) => {
  const { users, events, options } = props;
  const {
    page,
    setPage,
    rowsPerPage,
    getPaginatedRows
  } = usePagination(props)

  return <TableUsersContainer>
    <TableContainer>
      <Table
        sx={{ minWidth: 750 }}
        aria-labelledby="table-users"
      >
        <TableHead>
          <TableRow>
            {USER_TABLE_KEYS.map((tableKey) =>
              <TableCell
                key={tableKey.id}
                align={tableKey.id === 'actions' ? 'right' : 'left'}
                sortDirection={'desc'}
              >
                {tableKey.id === 'actions' && <Button
                  className="table-users-add-button"
                  variant="contained"
                  color="success"
                  onClick={() => {
                    events.onAddRow && events.onAddRow()
                  }}
                >
                  Add user
                </Button>
                }

                {
                  tableKey.options?.sort
                    ? < TableSortLabel
                      active={true}
                      direction={'desc'}
                      onClick={() => { }}
                    >
                      {tableKey.name}
                    </TableSortLabel>
                    : <>{tableKey.name}</>
                }
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {users.length > 0 &&
            getPaginatedRows()
              .map((user, index) =>
                <TableRow
                  hover
                  onClick={(event) => { }}
                  role="checkbox"
                  tabIndex={-1}
                  key={user.email}
                >
                  <TableCell
                    component="th"
                    id={user.email}
                    scope="row"
                  >
                    {user.email}
                  </TableCell>

                  <TableCell
                    align="left"
                  >
                    {user.first_name || '-'}
                    {/* {user.force_most_economic_ride ? '✔️' : '✖️'} */}
                  </TableCell>
                  <TableCell
                    align="left"
                  >
                    {user.last_name || '-'}
                  </TableCell>
                  <TableCell
                    align="left"
                  >
                    {user.is_representative ? '✔️' : '✖️'}
                  </TableCell>
                  <TableCell
                    align="left"
                  >
                    {user.is_active ? '✔️' : '✖️'}
                  </TableCell>

                  <TableCell
                    align="left"
                  >
                    {(user?.policy as any)?.name || '-'}
                  </TableCell>

                  <TableCell
                    align="right"
                  >
                    <div
                      className="row-actions"
                    >
                      <div
                        className="edit-icon row-action"
                        data-tip
                        data-for="edit-tooltip"
                        onClick={() => {
                          events.onEditRow && events.onEditRow(user)
                        }}
                      >
                        <EditIcon />
                        <ReactTooltip
                          id="edit-tooltip"
                          backgroundColor="black"
                        >
                          Edit
                        </ReactTooltip>
                      </div>
                      <div
                        className="duplicate-icon row-action"
                        data-tip
                        data-for="duplicate-tooltip"
                        onClick={() => {
                          events.onChangePassword && events.onChangePassword(user)
                        }}
                      >
                        <ControlPointDuplicateIcon />
                        <ReactTooltip
                          id="duplicate-tooltip"
                          backgroundColor="black"
                        >
                          Change password
                        </ReactTooltip>
                      </div>
                      <div
                        className="delete-icon row-action"
                        data-tip
                        data-for="delete-tooltip"
                        onClick={() => {
                          events.onDeleteRow && events.onDeleteRow(user)
                        }}
                      >
                        <DeleteIcon />
                        <ReactTooltip
                          id="delete-tooltip"
                          backgroundColor="black"
                        >
                          Delete
                        </ReactTooltip>
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              )}
        </TableBody>
      </Table>
    </TableContainer>

    {options?.pageLength && <TablePagination
      rowsPerPageOptions={[options.pageLength]}
      component="div"
      count={users.length}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={(event, newPage) => setPage(newPage)}
    />}
  </TableUsersContainer >
}

export default TableUsers