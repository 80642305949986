import { useContext } from "react"
import { getPolicyViolationsRequest, IGetPolicyViolationsRequestParams } from "../../lib/dime-api/requests/policy-violations/get-policy-violations"
import { StoreContext } from "../../lib/store/store"
import { LocalStorageConnector } from '../../lib/localstorage/localstorage'
import { IGetPolicyViolationRequestParams, getPolicyViolationRequest } from "../../lib/dime-api/requests/policy-violations/get-policy-violation"

export const usePolicyBreachService = () => {
  const store = useContext(StoreContext)


  const getPolicyBreaches = async (params?: IGetPolicyViolationsRequestParams) => {
    try {
      store.setLoading(true)

      const token = LocalStorageConnector.getElement('token');

      const response = await getPolicyViolationsRequest(token, params || {})
      store.setLoading(false)

      return response.data.results
    } catch (error) {
      console.log('getPolicies error', error)
      store.setLoading(false)
      throw error
    }
  }

  const getPolicyBreachById = async (id: string) => {
    try {

      const token = LocalStorageConnector.getElement('token');

      const response = await getPolicyViolationRequest(token, { id })

      return response.data
    } catch (error) {
      console.log('getPolicies error', error)
      throw error
    }
  }



  return {
    getPolicyBreaches,
    getPolicyBreachById
  }
}