import axios, { AxiosPromise } from "axios";
import { getHeaders, IDataList, SERVICE_URL } from "../../adapters/dime-api-adapter";
import { DashboardApiMonitoringRideListViewOutput } from "../../adapters/dime-api-client";

export interface IGetMonitoringRidesRequestParams {
  search?: string;
  ordering?: string;
  limit?: number;
  offset?: number; 
  from_date?: string;
  to_date?: string;
}

export const getMonitoringRidesRequest = (
  token: string,
  params?: IGetMonitoringRidesRequestParams,
): AxiosPromise<IDataList<DashboardApiMonitoringRideListViewOutput>> => {
  const endpoint = `${SERVICE_URL}/dashboard-api/monitoring/rides/`;

  return axios({
    method: "GET",
    url: endpoint,
    headers: getHeaders(token),
    params
  });
};