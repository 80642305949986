import styled from "@emotion/styled"

const UsersContainer = styled.div`
  .users-title {
    margin-bottom: 24px; 
  }

  .users-filter {
    margin-bottom: 24px;
    border-radius: 16px; 
    box-shadow: 2px 2px 15px 0 #e5e5e5;

    .users-filter-form {
      display: flex; 
      align-items: center; 
      padding: 16px; 

      .filter-field {
        margin-right: 16px; 
        width: 100%; 
      }

      .users-filter-button {
        max-width: 100px;
      }
    }
  }
`

export default UsersContainer