export const SERVICE_URL: string = process.env.REACT_APP_API_URL;

export const getHeaders = (token?: string) => {
  return {
    "Content-Type": "application/json",
    ...(token ? { Authorization: `Bearer ${token}` } : {}),
  };
};

export interface IDataList<ElementType> {
  count: number; 
  next?: string; 
  previous?: string;
  results: ElementType[]
}
