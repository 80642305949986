import { useState } from 'react'

export interface IModalContext {
  open: boolean;
  text?: string;
}

const INITIAL_MODAL_STATE = { open: false }
export const INITIAL_MODAL_CONTEXT = {
  modalError: INITIAL_MODAL_STATE,
  setModalError: () => { },
  modalInfo: INITIAL_MODAL_STATE,
  setModalInfo: () => { }
}

export const useModalContext = () => {
  const [modalError, setModalError] = useState<IModalContext>(INITIAL_MODAL_STATE)
  const [modalInfo, setModalInfo] = useState<IModalContext>(INITIAL_MODAL_STATE)
  return {
    modalError,
    setModalError,
    modalInfo,
    setModalInfo
  }
}