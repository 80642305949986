import { Button, Link, TextField, Typography } from '@mui/material';
import React from 'react'
import Svg from '../../components/svg/svg';

import logoIcon from '../../assets/icons/files/small-logo.svg'
import LoginContainer from './login-style';
import { IFormValidations, ISubmitUseFormParams, IValidation } from '../../hooks/useForm/use-form-types';
import { RequiredValidation, EmailValidation } from '../../hooks/useForm/use-form-validations';
import { useAuthService } from '../../services/auth/auth-service';
import useForm from '../../hooks/useForm/use-form';
import ModalLayout from '../../components/layout/modal-layout/modal-layout';
import ModalError from '../../components/modals/modal-error/modal-error';
import { Link as ReactLink } from "react-router-dom";
import { routes } from '../../constants/routes';

interface IFormValues {
  email: string;
  password: string;
}

const requiredValidation: IValidation = RequiredValidation();
const emailValidation: IValidation = EmailValidation()

const formValidations: IFormValidations<IFormValues> = {
  email: [requiredValidation, emailValidation],
  password: [requiredValidation],
}
const useLogin = () => {
  const authService = useAuthService()

  const initialValues: IFormValues = {
    email: '',
    password: ''
  }

  const submit = async (params: ISubmitUseFormParams<IFormValues>) => {
    await authService.loginUser(
      {
        ...params.values
      },
    )

    window.location.reload()
  }

  const formActions = useForm<IFormValues>(
    {
      initialValues,
      formValidations,
      submit
    }
  )

  return {
    ...formActions
  }

}

const LoginPage: React.FC = () => {
  const actions = useLogin();

  return <LoginContainer>
    <section className="login-section">
      <div className="login-logo">
        <Svg
          src={logoIcon}
        />
      </div>
      <Typography
        component="h1"
        variant="h4"
        className="login-title"
      >
        Sign in
      </Typography>

      <form
        className="login-form"
        id='login-form'
        onSubmit={(event) => {
          event.preventDefault();
          actions.onSubmit()
        }}
      >
        <TextField
          className="login-field"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
          onChange={(event) => actions.handleFieldEvent(event.target.value, "email")}
        />
        <TextField
          className="login-field"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          onChange={(event) => actions.handleFieldEvent(event.target.value, "password")}
        />
      </form>

      <Button
        className="login-button"
        type="submit"
        fullWidth
        variant="contained"
        color="success"
        form="login-form"
      >
        Sign In
      </Button>


      <ReactLink to={routes.forgotPassword}>
        <Link variant="body2">
          Forgot password?
        </Link>
      </ReactLink>
    </section>
  </LoginContainer>
}

export default LoginPage; 