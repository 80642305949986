import styled from "@emotion/styled"
import { colors } from "../../../assets/colors"

const ChangeUserPasswordContainer = styled.div`
  .change-user-password-title {
    margin-bottom: 24px;
  }

  .change-user-password-form { 
    width: 100%;
    max-width: 600px;
    margin-bottom: 24px;

    .change-user-password-field {
      margin-bottom: 16px;
    }

    .change-user-password-2-row {
      display: flex;
      align-items: flex-start; 
      justify-content: space-between; 
      margin-bottom: 16px; 
      
      .change-user-password-field {
        margin-bottom: 0;
        margin-right: 16px; 
        width: calc((100% - 16px) / 2);
       
        &:last-child {
          margin-right: 0; 
        }
      }
    }

    .password-field {
      margin-bottom: 8px;
      width: 100%; 
    }

    .change-user-password-label {
      font-size: 12px; 
      color: ${colors.hardGray};
      margin-left: 16px; 
      margin-bottom: 16px;
      width: 100%; 
    }
  }

  .change-user-password-button {
    width: 200px;
  }
`

export default ChangeUserPasswordContainer