import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import MainLayout from '../../../components/layout/main-layout/main-layout'
import CompanyPage from '../../../pages/company/company'
import Monitoring from '../../../pages/monitoring/monitoring'
import PaymentMethodAddPage from '../../../pages/payment-method/payment-method-add/payment-method-add'
import PaymentMethodEditPage from '../../../pages/payment-method/payment-method-edit/payment-method-edit'
import PoliciesPage from '../../../pages/policies/policies'
import PoliciesCreatePage from '../../../pages/policies/policies-create/policies-create'
import PoliciesEditPage from '../../../pages/policies/policies-update/policies-edit'
import PolicyBreaches from '../../../pages/policy-breaches/policy-breaches'
import PolicyBreachesDetail from '../../../pages/policy-breaches/policy-breaches-detail/policy-breaches-detail'
import ChangeUserPasswordPage from '../../../pages/users/change-password/change-password'
import UsersPage from '../../../pages/users/users'
import UsersCreatePage from '../../../pages/users/users-create/users-create'
import UsersUpdatePage from '../../../pages/users/users-update/users-update'

const PrivateRoutes: React.FC = () => {
  return <BrowserRouter>
    <MainLayout hasHeader hasAside>
      <Routes>
        <Route path='/' element={<Monitoring />} />
        <Route path='/monitoring' element={<Monitoring />} />
        <Route path='/company' element={<CompanyPage />} />
        <Route path='/payment-method/add' element={<PaymentMethodAddPage />} />
        <Route path='/payment-method/:id/edit' element={<PaymentMethodEditPage />} />
        <Route path='/policies' element={<PoliciesPage />} />
        <Route path='/policies/create' element={<PoliciesCreatePage />} />
        <Route path='/policies/:id/edit' element={<PoliciesEditPage />} />
        <Route path='/policy-breaches' element={<PolicyBreaches />} />
        <Route path='/policy-breaches/:id' element={<PolicyBreachesDetail />} />
        <Route path='/users' element={<UsersPage />} />
        <Route path='/users/create' element={<UsersCreatePage />} />
        <Route path='/users/:id/edit' element={<UsersUpdatePage />} />
        <Route path='/users/:id/change-password' element={<ChangeUserPasswordPage />} />
      </Routes>
    </MainLayout>

  </BrowserRouter>
}


export default PrivateRoutes