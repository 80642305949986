import styled from "@emotion/styled";

const MainAsideStyle = styled.div`
  .main-aside > div {
    box-shadow: 2px 2px 15px 0 #e5e5e5;
  }

  .drawer-header {
    max-height: 64px;
  }
`

export default MainAsideStyle