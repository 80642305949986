import React, { useContext, useEffect, useState } from 'react'
import CardNumber from '../../components/cards/card-number/card-number';
import MonitoringContainer from './monitoring-style';
import { colors } from '../../assets/colors';


import { Button, TextField, Typography } from '@mui/material';
import ExampleTable from '../../components/main-table/ main-table';
import MonitoringNumbers from '../../containers/monitoring/monitoring-numbers/monitoring-numbers';
import MainTabs from '../../components/main-tabs/main-tabs';
import { useMonitoringService } from '../../services/monitoring/monitoring-service';
import { IGetMonitoringSummaryRequestParams } from '../../lib/dime-api/requests/monitoring/monitoring-summary-request';
import { useQuery } from 'react-query';
import { IGetMonitoringUsersRequestParams } from '../../lib/dime-api/requests/monitoring/monitoring-users-request';
import { IGetMonitoringRidesRequestParams } from '../../lib/dime-api/requests/monitoring/monitoring-rides-request';
import TableMonitoringUsers from '../../components/tables/table-monitoring-users/table-monitoring-users';
import { StoreContext } from '../../lib/store/store';
import TableMonitoringRides from '../../components/tables/table-monitoring-rides/table-monitoring-rides';
import { MobileDatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ballTriangleLoader from '../../assets/icons/files/ball-triangle-loader.svg'
import Svg from '../../components/svg/svg';

interface IFormValues {
  from_date: string;
  to_date: string;
}

const useMonitoringSummaryData = () => {
  const [monitoringSummaryFilter, setMonitoringSummaryFilter] = useState<IGetMonitoringSummaryRequestParams>({})
  const monitoringService = useMonitoringService()

  const { data: monitoringSummary, error, isLoading, refetch: refetchPolicies } = useQuery(
    ["monitoringSummary"],
    () => monitoringService.getMonitoringSummary(monitoringSummaryFilter),
    { retry: false },
  )

  useEffect(() => {
    console.log('refetch')
    refetchPolicies()
  }, [monitoringSummaryFilter])

  return {
    monitoringSummary,
    monitoringSummaryFilter,
    setMonitoringSummaryFilter,
    areLoadingSummary: isLoading
  }
}

const useMonitoringUsersData = () => {
  const [monitoringUsersFilter, setMonitoringUsersFilter] = useState<IGetMonitoringUsersRequestParams>({})
  const monitoringService = useMonitoringService()

  const { data: monitoringUsers, error, isLoading, refetch: refetchPolicies } = useQuery(
    ["monitoringUsers"],
    () => monitoringService.getMonitoringUsers(monitoringUsersFilter),
    { retry: false },
  )

  useEffect(() => {
    console.log('refetch')
    refetchPolicies()

  }, [monitoringUsersFilter])

  return {
    monitoringUsers,
    monitoringUsersFilter,
    setMonitoringUsersFilter,
    areLoadingUsers: isLoading
  }
}

const useMonitoringRidesData = () => {
  const [monitoringRidesFilter, setMonitoringRidesFilter] = useState<IGetMonitoringRidesRequestParams>({})
  const monitoringService = useMonitoringService()

  const { data: monitoringRides, error, isLoading, refetch: refetchPolicies } = useQuery(
    ["monitoringRides"],
    () => monitoringService.getMonitoringRides(monitoringRidesFilter),
    { retry: false },
  )

  useEffect(() => {
    console.log('refetch')
    refetchPolicies()
  }, [monitoringRidesFilter])

  return {
    monitoringRides,
    monitoringRidesFilter,
    setMonitoringRidesFilter,
    areLoadingRides: isLoading
  }
}



const useMonitoring = () => {
  const {
    monitoringSummary,
    monitoringSummaryFilter,
    setMonitoringSummaryFilter,
    areLoadingSummary
  } = useMonitoringSummaryData()

  const {
    monitoringUsers,
    monitoringUsersFilter,
    setMonitoringUsersFilter,
    areLoadingUsers
  } = useMonitoringUsersData()

  const {
    monitoringRides,
    monitoringRidesFilter,
    setMonitoringRidesFilter,
    areLoadingRides
  } = useMonitoringRidesData()

  const [filters, setFilters] = useState<IFormValues>({
    from_date: (new Date()).toISOString(),
    to_date: (new Date()).toISOString()
  })

  const store = useContext(StoreContext)

  useEffect(() => {
    console.log('set monitoringUsersFilter', monitoringUsersFilter !== filters)
    if (monitoringUsersFilter !== filters) {
      setMonitoringUsersFilter(filters)
    }
  }, [monitoringSummaryFilter])

  useEffect(() => {
    if (monitoringRidesFilter !== filters) {
      setMonitoringRidesFilter(filters)
    }
  }, [monitoringUsersFilter])

  const handleChangeFilters = (newFilters: IFormValues) => {
    setFilters(newFilters)
  }

  const submitFilters = () => {
    if (monitoringSummaryFilter !== filters) {
      setMonitoringSummaryFilter(filters)
    }

  }
  return {
    monitoringSummary,
    monitoringUsers,
    monitoringRides,
    filters,
    handleChangeFilters,
    submitFilters,
    areLoadingSummary,
    areLoadingUsers,
    areLoadingRides
  }
}

const Monitoring: React.FC = () => {
  const {
    monitoringSummary,
    monitoringUsers,
    monitoringRides,
    handleChangeFilters,
    submitFilters,
    filters,
    areLoadingSummary,
    areLoadingUsers,
    areLoadingRides
  } = useMonitoring()

  return <MonitoringContainer>

    <Typography
      component="h1"
      variant="h4"
      className="monitoring-title"
    >
      Monitoring
    </Typography>

    <section className="monitoring-filters">
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <form
          className="monitoring-filter-form"
          id="monitoring-filter-form"
          onSubmit={(event) => {
            event.preventDefault();
            submitFilters()
          }}
        >
          <div className="monitoring-filter-field">
            <MobileDatePicker
              value={filters.from_date ? (new Date(filters.from_date)) : ''}
              label="Start Date"
              onChange={(event: Date) => {
                handleChangeFilters({
                  from_date: event.toISOString(),
                  to_date: filters.to_date
                })
              }}
              renderInput={(params) =>
                <TextField {...params} />
              }
            />

          </div>
          <div className="monitoring-filter-field">
            <MobileDatePicker
              value={filters.to_date ? (new Date(filters.to_date)) : ''}
              label="End Date"
              onChange={(event: Date) => {
                handleChangeFilters({
                  from_date: filters.from_date,
                  to_date: event.toISOString()
                })
              }} renderInput={(params) =>
                <TextField {...params} />
              }
            />

          </div>
        </form>

        <Button
          className="monitoring-filter-button"
          type="submit"
          fullWidth
          variant="contained"
          color="success"
          form="monitoring-filter-form"
        >
          Filter
        </Button>
      </LocalizationProvider>
    </section>

    <section className="monitoring-section numbers">

      {areLoadingSummary ? <div className="numbers-loader">
        <Svg src={ballTriangleLoader} />
      </div>
        : <MonitoringNumbers
          user_count={monitoringSummary?.user_count || 0}
          ride_count={monitoringSummary?.ride_count || 0}
          violation_count={monitoringSummary?.violation_count || 0}
          possible_savings_in_cents={monitoringSummary?.possible_savings_in_cents || 0}
          savings_made_in_cents={monitoringSummary?.savings_made_in_cents || 0}
          total_expense_in_cents={monitoringSummary?.total_expense_in_cents || 0}
        />}
    </section>


    <section className="monitoring-section">
      <MainTabs
        tabs={[
          "Users",
          "Last rides",
        ]}
        components={[
          <>
            {areLoadingUsers ?
              <div className="numbers-loader">
                <Svg src={ballTriangleLoader} />
              </div>
              : <TableMonitoringUsers
                monitoringUsers={monitoringUsers || []}
                options={{
                  pageLength: 5
                }}
              />
            }
          </>,
          <>
            {areLoadingRides ?
              <div className="numbers-loader">
                <Svg src={ballTriangleLoader} />
              </div>
              : <TableMonitoringRides
                monitoringRides={monitoringRides || []}
                options={{
                  pageLength: 5
                }}
              />
            }
          </>
          ,
        ]}
      />
    </section>

  </MonitoringContainer>
}

export default Monitoring;