import axios, { AxiosPromise } from 'axios'
import { getHeaders, SERVICE_URL, IDataList } from '../../../adapters/dime-api-adapter';
import {  DashboardApiCompanyPaymentMethodListApiOutput } from '../../../adapters/dime-api-client';

export interface IGetCompanyPaymentMethodsRequestParams {
  search?:string;
  ordering?: string; 
}

export const getCompanyPaymentMethodsRequest = (
  token: string,
  params?: IGetCompanyPaymentMethodsRequestParams,
): AxiosPromise<
DashboardApiCompanyPaymentMethodListApiOutput[]
> => {
  const endpoint = `${SERVICE_URL}/dashboard-api/company/payment-methods/list/`;

  return axios({
    method: "GET",
    url: endpoint,
    headers: getHeaders(token),
    params
  });
};