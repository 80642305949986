import styled from "@emotion/styled";

const MonitoringContainer = styled.div`
  .monitoring-title {
    margin-bottom: 24px; 
  }

  .numbers-loader {
    width: 100%; 
    height: 280px;
    display: flex; 
    align-items: center; 
    box-shadow: 2px 2px 15px 0 #e5e5e5;
    border-radius: 8px; 
    padding: 0 8px; 
    background: white; 

    svg {
      width: 100px; 
      height: 100px;
    }
  }

  .monitoring-filters {
    display: flex; 
    align-items: flex-start; 
    
    .monitoring-filter-form {
      display: flex; 
      align-items: center; 
      margin-bottom: 16px;
      margin-right: 16px;

      .monitoring-filter-field {
        margin-right: 16px; 

        &:last-child {
          margin-right: 0; 
        }
      }
    }

    .monitoring-filter-button {
      width: 100px; 
      height: 56px;
    }
  }

  .monitoring-section {
    margin-bottom: 24px;
  }
`

export default MonitoringContainer