import axios, { AxiosPromise } from 'axios'
import { getHeaders, SERVICE_URL } from '../../adapters/dime-api-adapter';
import { DashboardApiPolicyViolationDetailOutput } from '../../adapters/dime-api-client';

export interface IGetPolicyViolationRequestParams {
 id: string;
}

export const getPolicyViolationRequest = (
  token: string,
  params?: IGetPolicyViolationRequestParams,
): AxiosPromise<DashboardApiPolicyViolationDetailOutput> => {
  const endpoint = `${SERVICE_URL}/dashboard-api/policy-violations/${params.id}/detail/`;

  return axios({
    method: "GET",
    url: endpoint,
    headers: getHeaders(token),
  });
};