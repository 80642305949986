import React from 'react'
import CardNumber from '../../../components/cards/card-number/card-number'
import MonitoringNumbersContainer from './monitoring-numbers-style'

import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ErrorIcon from '@mui/icons-material/Error';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import DescriptionIcon from '@mui/icons-material/Description';
import SavingsIcon from '@mui/icons-material/Savings';
import AddCardIcon from '@mui/icons-material/AddCard';
import { colors } from '../../../assets/colors';

interface MonitoringProps {
  user_count: number;
  ride_count: number;
  violation_count: number;
  possible_savings_in_cents: number;
  savings_made_in_cents: number;
  total_expense_in_cents: number;
}

const parseToStringDollars = (cents: number) => {
  const dollars = cents ? cents / 100 : 0;

  return cents ? `$${dollars}` : '$0.00'
}

const MonitoringNumbers: React.FC<MonitoringProps> = (props: MonitoringProps) => {
  return <MonitoringNumbersContainer>
    <article className="monitoring-number" >
      <CardNumber
        title={{
          tag: "h2",
          text: "Users"
        }}
        number={String(props.user_count || 0)}
        icon={<PeopleAltIcon style={{ color: colors.lightBlue }} />}
        colorIcon={colors.lightBlue}
      />
    </article>

    <article className="monitoring-number" >
      <CardNumber
        title={{
          tag: "h2",
          text: "Policy violations"
        }}
        number={String(props.violation_count || 0)}
        icon={<ErrorIcon style={{ color: colors.red }} />}
        colorIcon={colors.red}
      />
    </article>

    <article className="monitoring-number" >
      <CardNumber
        title={{
          tag: "h2",
          text: "Rides"
        }}
        number={String(props.ride_count || 0)}
        icon={<DirectionsCarIcon style={{ color: colors.green }} />}
        colorIcon={colors.green}
      />
    </article>

    <article className="monitoring-number" >
      <CardNumber
        title={{
          tag: "h2",
          text: "Total Expense"
        }}
        number={parseToStringDollars(props.total_expense_in_cents)}
        icon={<DescriptionIcon style={{ color: colors.purple }} />}
        colorIcon={colors.purple}
      />
    </article>

    <article className="monitoring-number" >
      <CardNumber
        title={{
          tag: "h2",
          text: "Possible savings"
        }}
        number={parseToStringDollars(props.possible_savings_in_cents)}
        icon={<AddCardIcon style={{ color: colors.orange }} />}
        colorIcon={colors.orange}
      />
    </article>

    <article className="monitoring-number" >
      <CardNumber
        title={{
          tag: "h2",
          text: "Saved"
        }}
        number={parseToStringDollars(props.savings_made_in_cents)}
        icon={<SavingsIcon style={{ color: colors.pink }} />}
        colorIcon={colors.pink}
      />
    </article>
  </MonitoringNumbersContainer>
}

export default MonitoringNumbers