import { TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import TablePolicyBreaches from '../../components/tables/table-policy-breaches/table-policy-breaches'
import { routes } from '../../constants/routes'
import { useDebounce } from '../../hooks/useDebounce/use-debounce'
import { IGetPolicyViolationsRequestParams } from '../../lib/dime-api/requests/policy-violations/get-policy-violations'
import { usePolicyBreachService } from '../../services/policy-breach/policy-breach-service'
import PolicyBreachesContainer from './policy-breaches-style'

const usePolicyBreachData = () => {
  const [policyBreachFilter, setPolicyBreachFilter] = useState<IGetPolicyViolationsRequestParams>({})
  const policyBreachService = usePolicyBreachService();

  const { debouncedValue, setValue: setSearchValue } = useDebounce(1000);

  const { data: policyBreaches, error, isLoading, refetch: refetchPolicies } = useQuery(
    ["policyBreaches"],
    () => policyBreachService.getPolicyBreaches(policyBreachFilter),
    { retry: false },
  )

  useEffect(() => {
    console.log('refetch')
    refetchPolicies()
  }, [policyBreachFilter])

  useEffect(() => {
    console.log('set filter', debouncedValue)

    if (debouncedValue) {
      setPolicyBreachFilter({ search: debouncedValue })

    } else {
      setPolicyBreachFilter({})
    }
  }, [debouncedValue])

  const searchPolicyBreaches = (search: string) => {
    setSearchValue(search)
  }

  return {
    policyBreaches,
    searchPolicyBreaches,
    policyBreachFilter
  }
}

const usePolicyBreachesPage = () => {
  const navigate = useNavigate()

  const {
    policyBreaches,
    searchPolicyBreaches,
    policyBreachFilter
  } = usePolicyBreachData()

  const handleOnClickRow = (id: string) => {
    navigate(routes.policyBreachDetail(id))
  }

  return {
    handleOnClickRow,
    policyBreaches,
    searchPolicyBreaches,
    policyBreachFilter
  }

}

const PolicyBreaches: React.FC = () => {
  const actions = usePolicyBreachesPage();

  return <PolicyBreachesContainer>
    <Typography
      component="h1"
      variant="h4"
      className="policy-breaches-title"
    >
      Policy breaches
    </Typography>

    <section className="policy-breaches-filter">
      <form
        className="policy-breaches-filter-form"
        id='policy-breaches-filter-form'
      >
        <TextField
          className="filter-field"
          required
          label="Search by Policy Name"
          name="search-field"
          autoFocus
          onChange={(event) => { actions.searchPolicyBreaches(event.target.value) }}
          size={'small'}
        />

        {/* <Button
          className="policy-breaches-filter-button"
          type="submit"
          fullWidth
          variant="contained"
          color="success"
          form="payment-method-form"
        >
          Filter
        </Button> */}
      </form>
    </section>

    <section className="table-section">
      {actions.policyBreaches &&
        <TablePolicyBreaches
          policyBreaches={actions.policyBreaches}
          options={{
            pageLength: 15
          }}
          events={{
            onClickRow: (id) => actions.handleOnClickRow(id)
          }}
        />
      }
    </section>
  </PolicyBreachesContainer>
}

export default PolicyBreaches