import styled from "@emotion/styled";

interface Props {
  open: boolean
}

const MainLayoutContainer = styled.div`

`

export default MainLayoutContainer;



