import { useContext } from "react";
import { getUsersRequest, IGetUsersRequestParams } from "../../lib/dime-api/requests/user/get-users";
import { StoreContext } from "../../lib/store/store";
import { LocalStorageConnector } from '../../lib/localstorage/localstorage'
import { createUserRequest } from "../../lib/dime-api/requests/user/create-user-request";
import { DashboardApiCreateUserInput, DashboardApiUpdateUserInput, DashboardApiUpdateUserPasswordInput } from "../../lib/dime-api/adapters/dime-api-client";
import { updateUserRequest } from "../../lib/dime-api/requests/user/update-user-request";
import { deleteUserRequest } from "../../lib/dime-api/requests/user/delete-user-request";
import { updateUserPasswordRequest } from "../../lib/dime-api/requests/user/update-user-password-request";
import { getUserByIdRequest } from "../../lib/dime-api/requests/user/get-user-requests";


export const useUserService = () => {
  const store = useContext(StoreContext)

  const getUsers = async (params?: IGetUsersRequestParams) => {
    try {
      store.setLoading(true)

      const token = LocalStorageConnector.getElement('token');

      const response = await getUsersRequest(token, params || {})
      store.setLoading(false)

      return response.data.results
    } catch (error) {
      console.log('getUsers error', error)
      store.setLoading(false)
      throw error
    }
  }

  const getUserById = async (id: string) => {
    try {
      store.setLoading(true)

      const token = LocalStorageConnector.getElement('token');

      const response = await getUserByIdRequest(token, id)
      store.setLoading(false)

      return response.data
    } catch (error) {
      console.log('getUsers error', error)
      store.setLoading(false)
      throw error
    }
  }

  const createUser = async (body?: DashboardApiCreateUserInput) => {
    try {
      store.setLoading(true)

      const token = LocalStorageConnector.getElement('token');

      await createUserRequest(token, body)
      store.setLoading(false)

    } catch (error) {
      console.log('create policy error', error)
      store.setModalError({
        open: true,
        text: 'Server error creating the user'
      })
      store.setLoading(false)
      throw error
    }
  }

  const updateUser = async (id: string, body?: DashboardApiUpdateUserInput) => {
    try {
      store.setLoading(true)

      const token = LocalStorageConnector.getElement('token');

      await updateUserRequest(token, id, body)
      store.setLoading(false)

    } catch (error) {
      console.log('update user error', error)
      store.setModalError({
        open: true,
        text: 'Server error updating the user'
      })
      store.setLoading(false)
      throw error
    }
  }

  const deleteUser = async (id: string) => {
    try {
      store.setLoading(true)

      const token = LocalStorageConnector.getElement('token');

      await deleteUserRequest(token, id)
      store.setLoading(false)

    } catch (error) {
      console.log('delete user error', error)
      store.setModalError({
        open: true,
        text: 'Server error deleting the user'
      })
      store.setLoading(false)
      throw error
    }
  }

  const updateUserPassword = async (id: string, body?: DashboardApiUpdateUserPasswordInput) => {
    try {
      store.setLoading(true)

      const token = LocalStorageConnector.getElement('token');

      await updateUserPasswordRequest(token, id, body)
      store.setLoading(false)

    } catch (error) {
      console.log('update user password error', error)
      store.setModalError({
        open: true,
        text: 'Server error updating the user password'
      })
      store.setLoading(false)
      throw error
    }
  }

  return {
    getUsers,
    getUserById,
    createUser,
    updateUser,
    deleteUser,
    updateUserPassword
  }

}