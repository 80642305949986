import axios, { AxiosPromise } from 'axios'
import { SERVICE_URL, getHeaders } from '../../adapters/dime-api-adapter';
import { ResetPasswordRequest, Success } from '../../adapters/dime-api-client';

export const resetPasswordRequest = (
  body: ResetPasswordRequest,
): AxiosPromise<Success> => {
  const endpoint = `${SERVICE_URL}/auth-api/reset-password/`;

  return axios({
    method: "POST",
    url: endpoint,
    headers: getHeaders(),
    data: body,
  });
};