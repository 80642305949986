import axios, { AxiosPromise } from 'axios'
import { getHeaders, SERVICE_URL } from '../../adapters/dime-api-adapter';
import { DashboardApiCreateUserInput, User } from '../../adapters/dime-api-client';

export const createUserRequest = (
  token: string,
  body: DashboardApiCreateUserInput,
): AxiosPromise<User> => {
  const endpoint = `${SERVICE_URL}/dashboard-api/users/create/`;

  return axios({
    method: "POST",
    url: endpoint,
    headers: getHeaders(token),
    data: body,
  });
};