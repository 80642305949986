import { useContext } from "react"
import { StoreContext } from "../../lib/store/store"
import { LocalStorageConnector } from '../../lib/localstorage/localstorage'
import { getPoliciesRequest, IGetPoliciesRequestParams } from "../../lib/dime-api/requests/policies/get-policies"
import { DashboardApiCreatePolicyInput, DashboardApiUpdatePolicyInput } from "../../lib/dime-api/adapters/dime-api-client"
import { createPolicyRequest } from "../../lib/dime-api/requests/policies/create-policy-request"
import { updatePolicyRequest } from "../../lib/dime-api/requests/policies/update-policy-request"
import { getPolicyByIdRequest } from "../../lib/dime-api/requests/policies/get-policy-by-id"
import { deletePolicyRequest } from "../../lib/dime-api/requests/policies/delete-policy-request"

export const usePolicyService = () => {
  const store = useContext(StoreContext)

  const getPolicies = async (params?: IGetPoliciesRequestParams) => {
    try {
      store.setLoading(true)

      const token = LocalStorageConnector.getElement('token');

      const response = await getPoliciesRequest(token, params || {})
      store.setLoading(false)

      return response.data.results
    } catch (error) {
      console.log('getPolicies error', error)
      store.setLoading(false)
      throw error
    }
  }

  const getPolicyById = async (id: string) => {
    try {
      store.setLoading(true)

      const token = LocalStorageConnector.getElement('token');

      const response = await getPolicyByIdRequest(token, id)
      store.setLoading(false)

      return response.data
    } catch (error) {
      console.log('getPolicies error', error)
      store.setLoading(false)
      throw error
    }
  }

  const createPolicy = async (body?: DashboardApiCreatePolicyInput) => {
    try {
      store.setLoading(true)

      const token = LocalStorageConnector.getElement('token');

      await createPolicyRequest(token, body)
      store.setLoading(false)

    } catch (error) {
      console.log('create policy error', error)
      store.setModalError({
        open: true,
        text: 'Server error creating the policy'
      })
      store.setLoading(false)
      throw error
    }
  }


  const updatePolicy = async (id: string, body?: DashboardApiUpdatePolicyInput) => {
    try {
      store.setLoading(true)

      const token = LocalStorageConnector.getElement('token');

      await updatePolicyRequest(token, id, body)
      store.setLoading(false)

    } catch (error) {
      console.log('update policy error', error)
      store.setModalError({
        open: true,
        text: 'Server error updating the policy'
      })
      store.setLoading(false)
      throw error
    }
  }

  const deletePolicy = async (id?: string) => {
    try {
      store.setLoading(true)

      const token = LocalStorageConnector.getElement('token');

      await deletePolicyRequest(token, id)
      store.setLoading(false)

    } catch (error) {
      console.log('delete policy error', error)
      store.setModalError({
        open: true,
        text: 'Server error deleting the policy'
      })
      store.setLoading(false)
      throw error
    }
  }

  return {
    getPolicies,
    updatePolicy,
    createPolicy,
    getPolicyById,
    deletePolicy
  }
}