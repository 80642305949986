import { Typography, TextField, Button } from "@mui/material"
import React from "react"
import PaymentMethodEditContainer from "./payment-method-edit-style"

const PaymentMethodEditPage: React.FC = () => {
  return <PaymentMethodEditContainer>
    <Typography
      component="h1"
      variant="h4"
      className="payment-method-title"
    >
      Edit payment method
    </Typography>

    <form
      className="payment-method-form"
      id='payment-method-form'
    >
      <TextField
        className="payment-method-field"
        required
        fullWidth
        label="Card Number"
        name="card-number-field"
        autoFocus
        onChange={(event) => { }}
      />
      <div className="card-attributes-fields">
        <TextField
          className="payment-method-field"
          required
          fullWidth
          label="Month"
          name="month-field"
          autoFocus
          onChange={(event) => { }}
        />


        <TextField
          className="payment-method-field"
          required
          fullWidth
          label="Year"
          name="year-field"
          autoFocus
          onChange={(event) => { }}
        />

        <TextField
          className="payment-method-field"
          required
          fullWidth
          label="CVC"
          name="cvc-field"
          autoFocus
          onChange={(event) => { }}
        />
      </div>
    </form>

    <Button
      className="payment-method-button"
      type="submit"
      fullWidth
      variant="contained"
      color="success"
      form="payment-method-form"
    >
      Save
    </Button>
  </PaymentMethodEditContainer>
}

export default PaymentMethodEditPage