import axios, { AxiosPromise } from 'axios'
import { getHeaders, SERVICE_URL } from '../../adapters/dime-api-adapter';
import { DashboardCompanyDetailViewOutput } from '../../adapters/dime-api-client';

export const getCompanyRequest = (
  token: string,
): AxiosPromise<DashboardCompanyDetailViewOutput> => {
  const endpoint = `${SERVICE_URL}/dashboard-api/company/detail/`;

  return axios({
    method: "GET",
    url: endpoint,
    headers: getHeaders(token),
  });
};