import { Typography } from '@mui/material';
import React from 'react'
import { THeadings } from '../../../utils/types/layout-types';
import CardNumberContainer from './card-number-style';

interface CardNumberProps {
  title: {
    tag: THeadings;
    text: string;
  }
  number: string;
  icon: any;
  colorIcon: string;
}

const CardNumber: React.FC<CardNumberProps> = (props: CardNumberProps) => {
  const { title, number, icon, colorIcon } = props;
  return <CardNumberContainer
    colorIcon={colorIcon}
  >
    <div className="card-number-icon">
      {icon}
    </div>
    <div className="card-number-content">
      <Typography
        component={title.tag}
        variant="body1"
        className="card-number-title"
      >
        {title.text}
      </Typography>

      <Typography
        component="p"
        variant="h2"
        className="card-number-number"
      >
        {number}
      </Typography>
    </div>

  </CardNumberContainer>
}

export default CardNumber; 