import styled from '@emotion/styled'
import { colors } from '../../../assets/colors'

const PolicyBreachesDetailContainer = styled.div`
   .policy-breach-title {
    margin-bottom: 16px;
  }

  .component-loader {
    width: 100%; 
    min-height: 140px;
    height: 100%;
    display: flex; 
    align-items: center; 
    box-shadow: 2px 2px 15px 0 #e5e5e5;
    border-radius: 8px; 
    padding: 0 8px; 
    background: white; 

    svg {
      width: 100px; 
      height: 100px;
    }
  }

  .policy-breach-sections {
    display: grid; 
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px;

  }

  .policy-breach-section {
    margin-bottom: 16px;

      .policy-breach-article-title {
        margin-bottom: 16px; 
      }

      .policy-breach-fields  {
        display: grid; 
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 16px;

        .policy-breach-field {

         .policy-breach-label {
           font-weight: 600;
           margin-bottom: 4px;
           color: ${colors.hardGray};
         
           .policy-breach-text {
             color: ${colors.black};
           }
         }
      }
    } 

    &.shadow {
      /* border: 1px solid ${colors.lightGreen}; */
      box-shadow: 2px 2px 15px 0 #e5e5e5;
      border-radius: 16px;
      padding: 24px;
    }
  }
`

export default PolicyBreachesDetailContainer