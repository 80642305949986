import styled from "@emotion/styled"

const PoliciesContainer = styled.div`
  .policies-title {
    margin-bottom: 24px; 
  }

  .policies-filter {
    margin-bottom: 24px;
    border-radius: 16px; 
    box-shadow: 2px 2px 15px 0 #e5e5e5;

    .policies-filter-form {
      display: flex; 
      align-items: center; 
      padding: 16px; 

      .filter-field {
        margin-right: 16px; 
        width: 100%; 
      }

      .policies-filter-button {
        max-width: 100px;
      }
    }
  }
`

export default PoliciesContainer