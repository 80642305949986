import { Button, Link, TextField, Typography } from '@mui/material';
import React, { useContext } from 'react'
import Svg from '../../components/svg/svg';

import logoIcon from '../../assets/icons/files/small-logo.svg'
import { IFormValidations, ISubmitUseFormParams, IValidation } from '../../hooks/useForm/use-form-types';
import { RequiredValidation, EmailValidation } from '../../hooks/useForm/use-form-validations';
import { useAuthService } from '../../services/auth/auth-service';
import useForm from '../../hooks/useForm/use-form';
import { routes } from '../../constants/routes';
import RequestPasswordContainer from './request-recover-password-style';
import { IStoreContext } from '../../lib/store/store-types';
import { StoreContext } from '../../lib/store/store';
import { useNavigate } from 'react-router-dom';

interface IFormValues {
  email: string;
}

const requiredValidation: IValidation = RequiredValidation();
const emailValidation: IValidation = EmailValidation()

const formValidations: IFormValidations<IFormValues> = {
  email: [requiredValidation, emailValidation],
}
const useRequestPassword = () => {
  const authService = useAuthService()
  const navigate = useNavigate()
  const store = useContext<IStoreContext>(StoreContext)

  const initialValues: IFormValues = {
    email: '',
  }

  const submit = async (params: ISubmitUseFormParams<IFormValues>) => {

    try {
      await authService.requestRecoverPassword(params.values)

      const successText = 'We have sent you an email to recover your password'
      store.setModalInfo({
        open: true,
        text: successText
      })

      navigate(routes.login)
    } catch (error) {

    }

  }

  const formActions = useForm<IFormValues>(
    {
      initialValues,
      formValidations,
      submit
    }
  )

  return {
    ...formActions
  }

}

const RequestPasswordPage: React.FC = () => {
  const actions = useRequestPassword();

  return <RequestPasswordContainer>

    <section className="request-password-section">
      <div className="request-password-logo">
        <Svg
          src={logoIcon}
        />
      </div>
      <Typography
        component="h1"
        variant="h4"
        className="request-password-title"
      >
        Forgot password?
      </Typography>

      <form
        className="request-password-form"
        id='request-password-form'
        onSubmit={(event) => {
          event.preventDefault();
          actions.onSubmit()
        }}
      >
        <TextField
          className="request-password-field"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
          onChange={(event) => actions.handleFieldEvent(event.target.value, "email")}
        />

      </form>

      <Button
        className="request-password-button"
        type="submit"
        fullWidth
        variant="contained"
        color="success"
        form="request-password-form"
      >
        Recover password
      </Button>


    </section>
  </RequestPasswordContainer>
}

export default RequestPasswordPage; 