import { Button, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { colors } from '../../assets/colors'
import ModalLayout from '../../components/layout/modal-layout/modal-layout'
import ExampleTable from '../../components/main-table/ main-table'
import MainTabs from '../../components/main-tabs/main-tabs'
import ModalConfirm from '../../components/modals/modal-confirm/modal-confirm'
import Svg from '../../components/svg/svg'
import TableCompanySubscriptions from '../../components/tables/table-company-subscripitions/table-company-subscriptions'
import TablePaymentMethods from '../../components/tables/table-payment-methods/table-payment-methods'
import { routes } from '../../constants/routes'
import CompanyDetail from '../../containers/company/company-detail/company-detail'
import CompanySubscription from '../../containers/company/company-subscription/company-subscription'
import { IAppCompanySubscription } from '../../entities/company-entity'
import { useCompanyService } from '../../services/company/company-service'
import CompanyPageContainer from './company-style'
import ballTriangleLoader from '../../assets/icons/files/ball-triangle-loader.svg'

interface IPaymentMethodAction {
  action: "EDIT" | "DEFAULT" | "DELETE",
  paymentMethodId: string;
}

const getModalConfirmText = (verb: string) => {
  return `Are you sure you want to ${verb} the payment method?`
}

const getUserDate = (isoStringDate) => {
  const date = new Date(isoStringDate);
  const options = {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false
  }
  return date.toLocaleDateString('en-EN', options as any);
}

const getPricePerUserSubscription = (subscription: IAppCompanySubscription) => {
  const amountInCents = subscription?.service_plan_price?.amount_in_cents_per_user
  return amountInCents ? `$ ${amountInCents / 100}` : '$ 0.00'
}

const getNextCyclePriceSubscription = (subscription: any) => {
  const amountInCents = subscription?.service_plan_price?.next_cycle_total?.amount_in_cents_per_user
  return amountInCents ? `$ ${amountInCents / 100}` : '$ 0.00'
}

const useCompanyData = () => {
  const companyService = useCompanyService()

  const { data: company, error, isLoading } = useQuery(
    ["company"],
    companyService.getOwnCompany,
    { retry: false },
  )

  return {
    company,
    isCompanyLoading: isLoading
  }
}

const usePaymentMethodTable = () => {
  const [paymentMethodAction, setPaymentMethodAction] = useState<IPaymentMethodAction>()
  const [modalConfirmText, setModalConfirmText] = useState<string>('')

  const navigate = useNavigate();


  const handleDeletePaymentMethod = (paymentMethodId: string) => {
    //TODO - Delete
  }

  const handleDefaultPaymentMethod = (paymentMethodId: string) => {
    //TODO - SET DEFAULT 
  }

  const handleEditPaymentMethod = (paymentMethodId: string) => {
    navigate(routes.paymentMethodEdit(paymentMethodId))

  }

  const handleAddPaymentMethod = () => {
    navigate(routes.paymentMethodAdd)
  }

  const handleClickPaymentMethodAction = (newPaymentMethodAction: IPaymentMethodAction) => {
    const confirmText = getModalConfirmText(newPaymentMethodAction.action);
    setModalConfirmText(confirmText)
    setPaymentMethodAction(newPaymentMethodAction)
  }

  const startPaymentMethodAction = () => {
    switch (paymentMethodAction.action) {
      case 'EDIT':
        handleEditPaymentMethod(paymentMethodAction.paymentMethodId)
        break;
      case 'DEFAULT':
        handleDefaultPaymentMethod(paymentMethodAction.paymentMethodId)
        break;
      case 'DELETE':
        handleDeletePaymentMethod(paymentMethodAction.paymentMethodId)
        break;
    }
  }

  return {
    startPaymentMethodAction,
    handleClickPaymentMethodAction,
    handleAddPaymentMethod,
    modalConfirmText,
    setModalConfirmText,
  }

}

const useCompanyPage = () => {
  const {
    company,
    isCompanyLoading
  } = useCompanyData()
  const paymentMethodTable = usePaymentMethodTable()


  return {
    company,
    isCompanyLoading,
    ...paymentMethodTable
  }
}

const CompanyPage: React.FC = () => {
  const {
    company,
    startPaymentMethodAction,
    handleClickPaymentMethodAction,
    handleAddPaymentMethod,
    modalConfirmText,
    setModalConfirmText,
    isCompanyLoading,
  } = useCompanyPage()

  return <CompanyPageContainer>
    <Typography
      component="h1"
      variant="h4"
      className="company-title"
    >
      Company {company?.name ? `: ${company?.name}` : ''}
    </Typography>

    <section className="company-section">
      <article className="company-info-article shadow">
        {isCompanyLoading ?
          <div className="component-loader">
            <Svg src={ballTriangleLoader} />
          </div>
          : <CompanyDetail
            address={company?.address || ''}
            phone_number={company?.phone_number || ''}
          />
        }
      </article>

      <article className="company-info-article shadow">
        {isCompanyLoading ?
          <div className="component-loader">
            <Svg src={ballTriangleLoader} />
          </div>
          : <CompanySubscription
            service_plan='Basic'
            price_per_user={company?.activeSubscription ? getPricePerUserSubscription(company?.activeSubscription) : '-'}
            interval_type={String(company?.activeSubscription?.service_plan_price?.interval) || '-'}
            intervals={String(company?.activeSubscription?.service_plan_price?.interval_count) || '-'}
            current_period_start={
              company?.activeSubscription?.current_period_start ? getUserDate(String(company?.activeSubscription?.current_period_start)) : ''
            }
            current_period_end={
              company?.activeSubscription?.current_period_end ? getUserDate(String(company?.activeSubscription?.current_period_end)) : ''
            }
            status={String(company?.activeSubscription?.status || '')}
            next_cycle_total={company?.activeSubscription ? getNextCyclePriceSubscription(company?.activeSubscription) : '-'}
          />
        }
      </article>
    </section>

    <section className="company-section">
      <MainTabs
        tabs={[
          "Subscription list",
          "Payment methods"
        ]}
        components={[
          <>
            {isCompanyLoading ?
              <div className="component-loader">
                <Svg src={ballTriangleLoader} />
              </div>
              : <TableCompanySubscriptions
                subscriptions={company?.subscriptions || []}
                options={{
                  pageLength: 5
                }}
              />
            }
          </>,
          <>
            {isCompanyLoading ?
              <div className="component-loader">
                <Svg src={ballTriangleLoader} />
              </div>
              : <TablePaymentMethods
                paymentMethods={company?.paymentMethods || []}
                options={{
                  pageLength: 5
                }}
                events={{
                  onAddRow: () => handleAddPaymentMethod(),
                  onEditRow: (paymentMethod) => handleClickPaymentMethodAction({
                    action: 'EDIT',
                    paymentMethodId: paymentMethod.id
                  }),
                  onDuplicateRow: (paymentMethod) => handleClickPaymentMethodAction({
                    action: 'DEFAULT',
                    paymentMethodId: paymentMethod.id
                  }),
                  onDeleteRow: (paymentMethod) => handleClickPaymentMethodAction({
                    action: 'DELETE',
                    paymentMethodId: paymentMethod.id
                  })
                }}
              />
            }
          </>
        ]}
      />
    </section>

    {modalConfirmText &&
      <ModalLayout>
        <ModalConfirm
          title={{
            text: "Information",
            tag: "h2"
          }}
          description={modalConfirmText}
          events={{
            onClose: () => setModalConfirmText(''),
            onAccept: () => startPaymentMethodAction()
          }}
          modal={{
            ariaModal: true
          }}
        />
      </ModalLayout>}

  </CompanyPageContainer>
}

export default CompanyPage