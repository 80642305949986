import styled from "@emotion/styled"

const PaymentMethodAddContainer = styled.div`
  .payment-method-title {
    margin-bottom: 24px;
  }

  .payment-method-form {
    width: 100%;
    max-width: 600px;
    margin-bottom: 24px;

    .payment-method-field {
      margin-bottom: 16px;
    }

    .card-attributes-fields {
      display: flex; 
      align-items: center; 

      .payment-method-field {
        margin-bottom: 0;
        margin-right: 16px;
        width: calc((100% - 16px) / 3);

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .payment-method-button {
    width: 100px;
  }
`

export default PaymentMethodAddContainer