import styled from "@emotion/styled"
import { colors } from "../../../assets/colors"

const UsersCreateContainer = styled.div`
  .users-create-title {
    margin-bottom: 24px;
  }

  .users-create-form { 
    width: 100%;
    max-width: 600px;
    margin-bottom: 24px;

    .users-create-field {
      margin-bottom: 16px;
    }

    .users-create-2-row {
      display: flex;
      align-items: center; 
      justify-content: space-between; 
      margin-bottom: 16px; 
      
      .users-create-field {
        margin-bottom: 0;
        margin-right: 16px; 
        width: calc((100% - 16px) / 2);
       
        &:last-child {
          margin-right: 0; 
        }
      }
    }

    .password-field {
      margin-bottom: 8px;
    }

    .users-update-password-label {
      font-size: 12px; 
      color: ${colors.hardGray};
      margin-left: 16px; 
      margin-bottom: 16px;
    }
  }

  .users-create-button {
    width: 100px;
  }

  .error-text {
    font-size: 12px; 
    color: ${colors.red};
  }
`

export default UsersCreateContainer