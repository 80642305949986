import axios, { AxiosPromise } from 'axios'
import { getHeaders, IDataList, SERVICE_URL } from '../../../adapters/dime-api-adapter';
import {  DashboardApiCompanySubscriptionListOutput } from '../../../adapters/dime-api-client';

export interface IGetCompanySubscriptionsRequest {
  search?: string;
  ordering?:string;
  limit?: string;
  offset?: string;
}

export const getCompanySubscriptionsRequest = (
  token: string,
  params?: IGetCompanySubscriptionsRequest
): AxiosPromise<IDataList<DashboardApiCompanySubscriptionListOutput>> => {
  const endpoint = `${SERVICE_URL}/dashboard-api/company/subscriptions/`;

  return axios({
    method: "GET",
    url: endpoint,
    headers: getHeaders(token),
    params
  });
};