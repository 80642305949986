import styled from '@emotion/styled'
import backgroundImage from '../../assets/images/files/hero-background.png'
import { colors } from "../../assets/colors"

const NewPasswordContainer = styled.div`
  width: 100%; 
  min-height: 100vh; 
  max-height: 100vh;
  display: flex; 
  align-items: center; 
  justify-content: center; 
  background: url(${backgroundImage});
  


  .request-password-section {
    display: flex; 
    flex-direction: column; 
    align-items: center; 
    width: 100%;
    max-width: 500px;
    padding: 24px;
    box-shadow: 2px 2px 15px 0 #e5e5e5;
    border-radius: 16px;
    background: ${colors.white};

    .request-password-title {
      margin-bottom: 24px; 
    }

    .request-password-form {
      margin-bottom: 32px; 
      width: 100%; 

      .request-password-field {
        width: 100%;
        margin-bottom: 24px; 

        &:last-child {
          margin-bottom: 0; 
        }
      }
    }

    .request-password-button {
      margin-bottom: 16px;
    }
  }
`

export default NewPasswordContainer