import axios, { AxiosPromise } from 'axios'
import { getHeaders, IDataList, SERVICE_URL } from '../../adapters/dime-api-adapter';
import { DashboardApiServiceListViewOutput } from '../../adapters/dime-api-client';

export interface IGetServicesRequestParams {
  search?: string;
  ordering?: string;
  limit?: number;
  offset?: number; 
}

export const getServicesRequest = (
  token: string,
  params?: IGetServicesRequestParams,
): AxiosPromise<IDataList<DashboardApiServiceListViewOutput>> => {
  const endpoint = `${SERVICE_URL}/dashboard-api/services/list/`;

  return axios({
    method: "GET",
    url: endpoint,
    headers: getHeaders(token),
    params
  });
};