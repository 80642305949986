import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import MainLayout from '../../../components/layout/main-layout/main-layout'
import LoginPage from '../../../pages/login/login'
import NewPasswordPage from '../../../pages/new-password/new-password'
import RequestPasswordPage from '../../../pages/request-recover-password/request-recover-password'

const PublicRoutes: React.FC = () => {
  return <BrowserRouter>
    <MainLayout>
      <Routes>
        <Route path='/' element={<LoginPage />} />
        <Route path='/login' element={<LoginPage />} />
        <Route path='/forgot-password' element={<RequestPasswordPage />} />
        <Route path='/new-password' element={<NewPasswordPage />} />
        <Route path="*" element={<LoginPage />} />
      </Routes>
    </MainLayout>
  </BrowserRouter>
}


export default PublicRoutes