import styled from "@emotion/styled"
import { colors } from "../../assets/colors"

const CompanyPageContainer = styled.div`
  
  .company-title {
    margin-bottom: 24px;
  }

  .component-loader {
    width: 100%; 
    height: 280px;
    display: flex; 
    align-items: center; 
    box-shadow: 2px 2px 15px 0 #e5e5e5;
    border-radius: 8px; 
    padding: 0 8px; 
    background: white; 

    svg {
      width: 100px; 
      height: 100px;
    }
  }

  .company-section {
    margin-bottom: 24px;
  }

  .company-info-article {
    margin-bottom: 24px;

    .company-info-article-top {
      display: flex; 
      align-items: center;
      justify-content: space-between;
    }

    .company-article-title {
      margin-bottom: 16px;
    }

    .company-info-list {
      display: grid; 
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 24px;

      .company-field {
        .company-label {
          font-weight: 600;
          margin-bottom: 4px;
          color: ${colors.hardGray};
        }

        .company-text {
          color: ${colors.black};
        }
      }
    }

    &.shadow {
      box-shadow: 2px 2px 15px 0 #e5e5e5;
      border-radius: 16px;
      padding: 24px;
    }
  }

  .payment-table-container {
    margin-bottom: 16px; 

    .payment-table-actions {
      display: flex; 
      align-items: center; 
      justify-content: space-between;
      margin-bottom: 16px;
    }
  }
`

export default CompanyPageContainer