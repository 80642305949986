export const colors = {
  black: "#000000",
  hardGray: "#666666",
  dark: "#1E1D28",
  gray: "#AAAAAA",
  lightGray: "#EEEEEE",
  white: "#FFFFFF",
  red: "#EB5757",
  hardGreen: "#219653",
  green: "#27AE60",
  lightGreen: "#C7EBD4",
  lightBlue: "#56CCF2",
  orange: "#f39c12",
  purple: "#8E6BBF",
  pink: "#FFA6C8"
};
