import { Button, Typography } from '@mui/material';
import React from 'react'
import { THeadings } from '../../../utils/types/layout-types';
import ModalErrorContainer from './modal-error-style';
import ErrorIcon from '@mui/icons-material/Error';
import Svg from '../../svg/svg';

interface ModalErrorProps {
  title: {
    text: string;
    tag: THeadings;
  };
  description: string;
  modal: {
    ariaLabelledBy?: string;
    ariaDescribedBy?: string;
    ariaModal: boolean;
    className?: string;
  }
  events: {
    onAccept: () => void;
    onClose: () => void;
  }
  maxWidth?: string;
}


const ModalError: React.FC<ModalErrorProps> = (props: ModalErrorProps) => {
  const {
    modal,
    title,
    description,
    maxWidth,
    events
  } = props;

  return <ModalErrorContainer
    role="dialog"
    aria-modal={modal.ariaModal || false}
    aria-labelledby={modal.ariaLabelledBy || undefined}
    aria-describedby={modal.ariaDescribedBy || undefined}
    maxWidth={maxWidth}

  >
    <div className="modal-error-top">
      <div className="modal-error-icon">
        <ErrorIcon />
      </div>
      <Typography
        component={title.tag}
        variant="h4"
        className="modal-error-title"
      >
        {title.text}
      </Typography>

    </div>

    <Typography
      component="p"
      variant="body1"
      className="modal-error-text"
    >
      {description}
    </Typography>

    <div className="modal-error-button-container" >
      <Button
        className="lmodal-error-button"
        type="submit"
        fullWidth
        variant="contained"
        color="error"
        onClick={() => events.onAccept()}
      >
        Accept
      </Button>
    </div>
  </ModalErrorContainer>
}

export default ModalError; 