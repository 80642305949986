import { Typography, Button } from '@mui/material'
import React from 'react'
import CompanyDetailContainer from './company-detail-style'

interface CompanyDetailProps {
  address: string;
  phone_number: string;
  events?: {
    onClickEdit?: () => void;
  }
}
const CompanyDetail: React.FC<CompanyDetailProps> = (props: CompanyDetailProps) => {

  return <CompanyDetailContainer>
    <ul className="company-detail-list">
      <li className="company-detail-field">
        <Typography
          component="p"
          variant="body1"
          className="company-detail-label"
        >
          ADDRESS
        </Typography>

        <Typography
          component="p"
          variant="body1"
          className="company-detail-text"
        >
          Rafael test del test 24 7ºA
        </Typography>
      </li>

      <li className="company-detail-field">
        <Typography
          component="p"
          variant="body1"
          className="company-detail-label"
        >
          PHONE
        </Typography>

        <Typography
          component="p"
          variant="body1"
          className="company-detail-text"
        >
          666666666
        </Typography>
      </li>

      {props.events?.onClickEdit && <li className="company-detail-field">
        <Button
          className="company-detail-edit-button"
          variant="contained"
          color="success"
          onClick={() => { }}
        >
          Edit
        </Button>
      </li>}
    </ul>
  </CompanyDetailContainer>
}

export default CompanyDetail