import axios, { AxiosPromise } from 'axios'
import { getHeaders, IDataList, SERVICE_URL } from '../../adapters/dime-api-adapter';
import { User } from '../../adapters/dime-api-client';

export interface IGetUsersRequestParams {
  search?: string;
  ordering?: string;
  limit?: number;
  offset?: number; 
}

export const getUsersRequest = (
  token: string,
  params?: IGetUsersRequestParams,
): AxiosPromise<IDataList<User>> => {
  const endpoint = `${SERVICE_URL}/dashboard-api/users/list/`;

  return axios({
    method: "GET",
    url: endpoint,
    headers: getHeaders(token),
    params
  });
};