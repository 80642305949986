import { Tabs, Tab } from '@mui/material';
import React from 'react';
import { colors } from '../../assets/colors';
import MainTabsContainer from './main-tabs-style'


interface MainTabsProps {
  tabs: string[]
  components: React.ReactNode[];
}

const MainTabs: React.FC<MainTabsProps> = (props: MainTabsProps) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return <MainTabsContainer>
    <div className="main-tabs-top">
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
      >
        {props.tabs.map((element: string, index: number) =>
          <Tab
            className="main-tabs-tab"
            label={element}
            key={index}
            {...{
              id: `simple-tab-${index}`,
              'aria-controls': `simple-tabpanel-${index}`,
            }}
          />
        )}
      </Tabs>
    </div>

    {props.components.map((element: React.ReactNode, index: number) =>
      <div
        role="tabpanel"
        className="main-tabs-panel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
      >
        {element}
      </div>
    )}
  </MainTabsContainer>
}

export default MainTabs