import { Button, TextField, Typography } from '@mui/material'
import React from 'react'
import PaymentMethodAddContainer from './payment-method-add-style'
import { CardElement, Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PaymentMethodAddForm from './payment-method-add-form/payment-method-add-form';

const stripePromise = loadStripe('pk_test_51HwXkMJCBvLCQWIpolCm2K3xkdNwC9Y580eFxPtfdEsdCGv2XWYA72GZ9JvkuUlZYj4zWnm0tcXfWxrfWq1EfQsp008jls0tHE');

const PaymentMethodAddPage: React.FC = () => {
  return <PaymentMethodAddContainer>
    <Typography
      component="h1"
      variant="h4"
      className="payment-method-title"
    >
      Add new payment method
    </Typography>

    <div
      className="payment-method-form"
    >
      <Elements stripe={stripePromise}>
        <PaymentMethodAddForm />
      </Elements>
    </div>


  </PaymentMethodAddContainer>
}

export default PaymentMethodAddPage