import React from 'react'
import ModalLayoutContainer from './modal-layout-style'

interface ModalLayoutProps {
  children?: React.ReactNode
}

const ModalLayout: React.FC<ModalLayoutProps> = (props: ModalLayoutProps) => {
  return <ModalLayoutContainer>
    {props.children}
  </ModalLayoutContainer>
}

export default ModalLayout