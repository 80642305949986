import { Button } from '@mui/material';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import React, { useContext } from 'react'
import { StoreContext } from '../../../../lib/store/store';
import PaymentMethodAddFormContainer from './payment-method-add-form-style';


const PaymentMethodAddForm: React.FC = () => {
  const stripe = useStripe();
  const elements = useElements();
  const store = useContext(StoreContext)

  const handleSubmitForm = async () => {
    store.setLoading(true)

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    store.setLoading(false)


    if (error) {
      console.log('error:', error);
      const errorText = 'An error has occurred saving the payment method'
      store.setModalError({
        open: true,
        text: errorText
      })
    } else {
      const successText = 'Payment method saved successfully'
      store.setModalInfo({
        open: true,
        text: successText
      })
      console.log('Payment method:', paymentMethod);
    }

  }

  return <PaymentMethodAddFormContainer
    id="payment-method-add-form"
    onSubmit={(event) => {
      event.preventDefault();
      handleSubmitForm()
    }}
  >
    <div className="field">
      <CardElement />
    </div>

    <Button
      className="payment-method-button"
      type="submit"
      fullWidth
      variant="contained"
      color="success"
      form="payment-method-add-form"
    >
      Save
    </Button>
  </PaymentMethodAddFormContainer>
}

export default PaymentMethodAddForm