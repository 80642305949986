import React, { useState } from 'react'
import ReactTooltip from "react-tooltip";

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ControlPointDuplicateIcon from '@mui/icons-material/ControlPointDuplicate';
import { TableContainer, Table, TableHead, TableRow, TableCell, Button, TableSortLabel, TableBody, TablePagination } from '@mui/material';
import TablePaymentMethodsContainer from './table-payment-methods-style';
import { IAppCompanyPaymentMethod } from '../../../entities/company-entity';

interface ITableKeys {
  name: string;
  id: string;
  options?: {
    sort?: boolean
  }
}

const PAYMENT_METHOD_TABLE_KEYS: ITableKeys[] = [
  {
    name: 'Brand',
    id: 'brand',
  },
  {
    name: 'Last4',
    id: 'last4',
  },
  {
    name: 'Exp month',
    id: 'expMonth',
  },
  {
    name: 'Exp year',
    id: 'expYear',
  },
  {
    name: 'Funding',
    id: 'funding',
  },
  {
    name: 'Country',
    id: 'country',
  },
  {
    name: ' ',
    id: 'actions'
  }

]


interface TablePaymentMethodsProps {
  paymentMethods: IAppCompanyPaymentMethod[]
  options?: {
    pageLength?: number
  }
  events?: {
    onAddRow?: () => void,
    onEditRow?: (paymentMethod: IAppCompanyPaymentMethod) => void,
    onDuplicateRow?: (paymentMethod: IAppCompanyPaymentMethod) => void,
    onDeleteRow?: (paymentMethod: IAppCompanyPaymentMethod) => void
  }
}

const usePagination = (props: TablePaymentMethodsProps) => {
  const { options, paymentMethods } = props
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(options?.pageLength || paymentMethods.length);

  const getPaginatedRows = () => {
    if (options?.pageLength) {

      return paymentMethods.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    }

    return paymentMethods
  }

  return {
    page,
    setPage,
    rowsPerPage,
    getPaginatedRows
  }

}
const TablePaymentMethods: React.FC<TablePaymentMethodsProps> = (props: TablePaymentMethodsProps) => {
  const { paymentMethods, events, options } = props;
  const {
    page,
    setPage,
    rowsPerPage,
    getPaginatedRows
  } = usePagination(props)

  return <TablePaymentMethodsContainer>
    <TableContainer>
      <Table
        sx={{ minWidth: 750 }}
        aria-labelledby="table-payment-methods"
      >
        <TableHead>
          <TableRow>
            {PAYMENT_METHOD_TABLE_KEYS.map((tableKey) =>
              <TableCell
                key={tableKey.id}
                align={tableKey.id === 'actions' ? 'right' : 'left'}
                sortDirection={'desc'}
              >
                {tableKey.id === 'actions' && <Button
                  className="table-paymentMethods-add-button"
                  variant="contained"
                  color="success"
                  size="small"
                  onClick={() => {
                    events.onAddRow && events.onAddRow()
                  }}
                >
                  Add
                </Button>
                }

                {
                  tableKey.options?.sort
                    ? < TableSortLabel
                      active={true}
                      direction={'desc'}
                      onClick={() => { }}
                    >
                      {tableKey.name}
                    </TableSortLabel>
                    : <>{tableKey.name}</>
                }
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {paymentMethods.length > 0 &&
            getPaginatedRows()
              .map((paymentMethod, index) =>
                <TableRow
                  hover
                  onClick={(event) => { }}
                  role="checkbox"
                  tabIndex={-1}
                  key={'-'}
                >
                  <TableCell
                    component="th"
                    id={paymentMethod.brand}
                    scope="row"
                  >
                    {paymentMethod.brand || '-'}
                  </TableCell>

                  <TableCell
                    align="left"
                  >
                    {paymentMethod.last4 || '-'}
                  </TableCell>
                  <TableCell
                    align="left"
                  >
                    {paymentMethod.exp_month || '-'}

                  </TableCell>
                  <TableCell
                    align="left"
                  >
                    {paymentMethod.exp_year || '-'}
                  </TableCell>
                  <TableCell
                    align="left"
                  >
                    {paymentMethod.funding || '-'}
                  </TableCell>

                  <TableCell
                    align="left"
                  >
                    {paymentMethod.country || '-'}
                  </TableCell>

                  <TableCell
                    align="right"
                  >
                    <div
                      className="row-actions"
                    >
                      {/* <div
                        className="edit-icon row-action"
                        data-tip
                        data-for="edit-tooltip"
                        onClick={() => {
                          events.onEditRow && events.onEditRow(paymentMethod)
                        }}
                      >
                        <EditIcon />
                        <ReactTooltip
                          id="edit-tooltip"
                          backgroundColor="black"
                        >
                          Edit
                        </ReactTooltip>
                      </div>
                      <div
                        className="duplicate-icon row-action"
                        data-tip
                        data-for="duplicate-tooltip"
                        onClick={() => {
                          events.onDuplicateRow && events.onDuplicateRow(paymentMethod)
                        }}
                      >
                        <ControlPointDuplicateIcon />
                        <ReactTooltip
                          id="duplicate-tooltip"
                          backgroundColor="black"
                        >
                          Set default
                        </ReactTooltip>
                      </div> */}
                      {/* <div
                        className="delete-icon row-action"
                        data-tip
                        data-for="delete-tooltip"
                        onClick={() => {
                          // events.onDeleteRow && events.onDeleteRow(paymentMethod)
                        }}
                      >
                        <DeleteIcon />
                        <ReactTooltip
                          id="delete-tooltip"
                          backgroundColor="black"
                        >
                          Delete
                        </ReactTooltip>
                      </div> */}
                    </div>
                  </TableCell>
                </TableRow>
              )}
        </TableBody>
      </Table>
    </TableContainer>

    {options?.pageLength && <TablePagination
      rowsPerPageOptions={[options.pageLength]}
      component="div"
      count={paymentMethods.length}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={(event, newPage) => setPage(newPage)}
    />}
  </TablePaymentMethodsContainer >
}

export default TablePaymentMethods