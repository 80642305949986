import { Button, debounce, TextField, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import ModalLayout from '../../components/layout/modal-layout/modal-layout'
import ModalConfirm from '../../components/modals/modal-confirm/modal-confirm'
import modalConfirm from '../../components/modals/modal-confirm/modal-confirm'
import ModalError from '../../components/modals/modal-error/modal-error'
import TablePolicies from '../../components/tables/table-policies/table-policies'
import TableUsers from '../../components/tables/table-users/table-users'
import { routes } from '../../constants/routes'
import { IAppPolicy } from '../../entities/policy-entity'
import { useDebounce } from '../../hooks/useDebounce/use-debounce'
import { IGetPoliciesRequestParams } from '../../lib/dime-api/requests/policies/get-policies'
import { IGetUsersRequestParams } from '../../lib/dime-api/requests/user/get-users'
import { StoreContext } from '../../lib/store/store'
import { IStoreContext } from '../../lib/store/store-types'
import { usePolicyService } from '../../services/policy/policy-service'
import { useUserService } from '../../services/user/user-service'
import UsersContainer from './users-style'

interface IUserAction {
  action: "EDIT" | "CHANGE PASSWORD" | "DELETE",
  policyId: number;
}

const getModalConfirmText = (verb: string) => {
  return `Are you sure you want to ${verb} the user?`
}

const useUserData = () => {
  const [userFilter, setUserFilter] = useState<IGetUsersRequestParams>({})
  const userService = useUserService()
  const { debouncedValue, setValue: setSearchValue } = useDebounce(1000);

  const { data: users, error, isLoading, refetch: refetchUsers } = useQuery(
    ["userList"],
    () => userService.getUsers(userFilter),
    { retry: false },
  )

  useEffect(() => {
    refetchUsers()
  }, [userFilter])

  useEffect(() => {
    if (debouncedValue) {
      setUserFilter({ search: debouncedValue })

    } else {
      setUserFilter({})
    }
  }, [debouncedValue])

  const searchUsers = (search: string) => {
    setSearchValue(search)
  }

  return {
    users,
    searchUsers,
    setUserFilter
  }
}

const useUsersPage = () => {
  const navigate = useNavigate()
  const userService = useUserService()

  const [policyAction, setUserAction] = useState<IUserAction>()
  const [modalConfirmText, setModalConfirmText] = useState<string>('')

  const {
    users,
    searchUsers
  } = useUserData()

  const handleDeleteUser = async (userId: number) => {
    await userService.deleteUser(String(userId))
    setModalConfirmText('')
  }

  const handleChangePasswordUser = (userId: number) => {
    navigate(routes.changeUserPassword(String(userId)))
  }

  const handleEditUser = (userId: number) => {
    navigate(routes.userUpdate(String(userId)))

  }

  const handleAddUser = () => {
    navigate(routes.userCreate)
  }

  const handleClickUserAction = (newPolicyAction: IUserAction) => {
    const confirmText = getModalConfirmText(newPolicyAction.action);
    setModalConfirmText(confirmText)
    setUserAction(newPolicyAction)
  }

  const startUserAction = () => {
    switch (policyAction.action) {
      case 'EDIT':
        handleEditUser(policyAction.policyId)
        break;
      case 'CHANGE PASSWORD':
        handleChangePasswordUser(policyAction.policyId)
        break;
      case 'DELETE':
        handleDeleteUser(policyAction.policyId)
        break;
    }
  }

  return {
    handleAddUser,
    startUserAction,
    handleClickUserAction,
    modalConfirmText,
    setModalConfirmText,
    users,
    searchUsers
  }
}

const UsersPage: React.FC = () => {
  const actions = useUsersPage();

  return <UsersContainer>
    <Typography
      component="h1"
      variant="h4"
      className="users-title"
    >
      Users
    </Typography>

    <section className="users-filter">
      <form
        className="users-filter-form"
        id='users-filter-form'
      >
        <TextField
          className="filter-field"
          required
          label="Search by email"
          name="search-field"
          autoFocus
          onChange={(event) => { actions.searchUsers(event.target.value) }}
          size={'small'}
        />

        {/* <Button
          className="users-filter-button"
          type="submit"
          fullWidth
          variant="contained"
          color="success"
          form="payment-method-form"
        >
          Filter
        </Button> */}
      </form>
    </section>

    <section className="table-section">
      {actions.users &&
        <TableUsers
          users={actions.users}
          options={{
            pageLength: 15
          }}
          events={{
            onAddRow: () => actions.handleAddUser(),
            onEditRow: (policy) => actions.handleClickUserAction({
              action: 'EDIT',
              policyId: policy.id
            }),
            onChangePassword: (policy) => actions.handleClickUserAction({
              action: 'CHANGE PASSWORD',
              policyId: policy.id
            }),
            onDeleteRow: (policy) => actions.handleClickUserAction({
              action: 'DELETE',
              policyId: policy.id
            })
          }}
        />
      }
    </section>

    {actions.modalConfirmText &&
      <ModalLayout>
        <ModalConfirm
          title={{
            text: "Information",
            tag: "h2"
          }}
          description={actions.modalConfirmText}
          events={{
            onClose: () => actions.setModalConfirmText(''),
            onAccept: () => actions.startUserAction()
          }}
          modal={{
            ariaModal: true
          }}
        />
      </ModalLayout>}

  </UsersContainer>
}

export default UsersPage