import React, { useContext, useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import MainWrapper from '../../wrappers/main-wrapper';
import MainAside from '../../main-aside/main-aside';
import MainHeader from '../../main-header/main-header';
import { useAuthService } from '../../../services/auth/auth-service';
import { StoreContext } from '../../../lib/store/store';
import MainLoader from '../../main-loader/main-loader';
import MainLayoutContainer from './main-layout-style';
import ModalError from '../../modals/modal-error/modal-error';
import { IStoreContext } from '../../../lib/store/store-types';
import ModalLayout from '../modal-layout/modal-layout';
import ModalInfo from '../../modals/modal-info/modal-info';

interface MainLayoutProps {
  children?: React.ReactNode
  hasHeader?: boolean;
  hasAside?: boolean;
}

const drawerWidth = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const useAsideAnimation = () => {
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return {
    open,
    setOpen,
    handleDrawerOpen,
    handleDrawerClose
  }
}

const useMainLayout = () => {
  const asideAnimation = useAsideAnimation()
  const authService = useAuthService()
  const store = useContext<IStoreContext>(StoreContext)

  return {
    ...asideAnimation,
    authService,
    store
  }
}

const MainLayout: React.FC<MainLayoutProps> = (props: MainLayoutProps) => {
  const {
    open,
    handleDrawerOpen,
    handleDrawerClose,
    store
  } = useMainLayout()

  return <MainLayoutContainer>
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {
        props.hasHeader && <MainHeader
          open={open}
          onClickMenu={() => handleDrawerOpen()}
        />
      }

      {
        props.hasAside && <MainAside
          open={open}
          handleDrawerClose={() => handleDrawerClose()}
        />
      }

      {
        (props.hasAside || props.hasHeader) ?
          <MainWrapper open={open}>
            <DrawerHeader />
            {props.children}
          </MainWrapper>
          : <>{props.children}</>
      }
    </Box>

    {store.loading && <MainLoader />}
    {store.modalError.open && <ModalLayout>
      <ModalError
        title={{
          text: "Error",
          tag: "h2"
        }}
        description={store.modalError.text || 'Ha ocurrido un error'}
        events={{
          onClose: () => { store.setModalError({ open: false, text: '' }) },
          onAccept: () => { store.setModalError({ open: false, text: '' }) }
        }}
        modal={{
          ariaModal: true
        }}
      />
    </ModalLayout>
    }

    {store.modalInfo.open && <ModalLayout>
      <ModalInfo
        title={{
          text: "Information",
          tag: "h2"
        }}
        description={store.modalInfo.text || 'Information'}
        events={{
          onClose: () => { store.setModalInfo({ open: false, text: '' }) },
          onAccept: () => { store.setModalInfo({ open: false, text: '' }) }
        }}
        modal={{
          ariaModal: true
        }}
      />
    </ModalLayout>
    }
  </MainLayoutContainer >

}

export default MainLayout