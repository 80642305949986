import { TableContainer, Table, TableHead, TableRow, TableCell, Button, TableSortLabel, TableBody, TablePagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import { DashboardApiMonitoringRideListViewOutput } from "../../../lib/dime-api/adapters/dime-api-client";
import TableMonitoringRidesContainer from "./table-monitoring-rides-style";

interface ITableKeys {
  name: string;
  id: string;
  options?: {
    sort?: boolean
  }
}

const TABLE_KEYS: ITableKeys[] = [
  {
    name: 'Provider',
    id: 'provider',
  },
  {
    name: 'Service',
    id: 'service',
  },
  {
    name: 'Ride Datetime',
    id: 'rideDatetime',
  },
  {
    name: 'Price',
    id: 'price',
  },
  {
    name: 'Vs comparable',
    id: 'vsComparable',
  },
  {
    name: 'User',
    id: 'user',
  }
]


interface TableMonitoringRidesProps {
  monitoringRides: DashboardApiMonitoringRideListViewOutput[]
  options?: {
    pageLength?: number
  }
}

const formatTableDate = (isoStringDate) => {
  const date = new Date(isoStringDate);
  const options = {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false
  }
  return date.toLocaleDateString('en-EN', options as any);
}

const usePagination = (props: TableMonitoringRidesProps) => {
  const { options, monitoringRides } = props
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(options?.pageLength || monitoringRides.length);

  useEffect(() => {
    console.log('cambio', monitoringRides)
  }, [monitoringRides])

  const getPaginatedRows = () => {
    if (options?.pageLength) {
      return monitoringRides.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    }


    return monitoringRides
  }

  return {
    page,
    setPage,
    rowsPerPage,
    getPaginatedRows
  }

}
const TableMonitoringRides: React.FC<TableMonitoringRidesProps> = (props: TableMonitoringRidesProps) => {
  const { monitoringRides, options } = props;
  const {
    page,
    setPage,
    rowsPerPage,
    getPaginatedRows
  } = usePagination(props)

  return <TableMonitoringRidesContainer>
    <TableContainer>
      <Table
        sx={{ minWidth: 750 }}
        aria-labelledby="table-monitoring-rides"
      >
        <TableHead>
          <TableRow>
            {TABLE_KEYS.map((tableKey) =>
              <TableCell
                key={tableKey.id}
                align={tableKey.id === 'actions' ? 'right' : 'left'}
                sortDirection={'desc'}
              >
                {
                  tableKey.options?.sort
                    ? < TableSortLabel
                      active={true}
                      direction={'desc'}
                      onClick={() => { }}
                    >
                      {tableKey.name}
                    </TableSortLabel>
                    : <>{tableKey.name}</>
                }
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {monitoringRides.length > 0 &&
            getPaginatedRows()
              .map((monitoringRide, index) =>
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={index}
                >
                  <TableCell
                    component="th"
                    scope="row"
                  >
                    {monitoringRide?.provider?.name || '-'}
                  </TableCell>

                  <TableCell
                    align="left"
                  >
                    {monitoringRide?.service?.name || '-'}
                  </TableCell>
                  <TableCell
                    align="left"
                  >
                    {'-'}
                  </TableCell>
                  <TableCell
                    align="left"
                  >
                    {monitoringRide.price_in_cents ? `$${monitoringRide.price_in_cents / 100}` : '-'}

                  </TableCell>
                  <TableCell
                    align="left"
                  >
                    {monitoringRide.vs_comparable_in_cents ? `$${monitoringRide.vs_comparable_in_cents / 100}` : '-'}

                  </TableCell>
                  <TableCell
                    align="left"
                  >
                    {'-'}

                  </TableCell>
                </TableRow>
              )}
        </TableBody>
      </Table>
    </TableContainer>

    {options?.pageLength && <TablePagination
      rowsPerPageOptions={[options.pageLength]}
      component="div"
      count={monitoringRides.length}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={(event, newPage) => setPage(newPage)}
    />}
  </TableMonitoringRidesContainer >
}

export default TableMonitoringRides