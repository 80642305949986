import { useState } from "react"


export const useLoadingContext = () => {
  const [loading, setLoading] = useState<boolean>(false)

  return {
    loading,
    setLoading
  }

}