import styled from "@emotion/styled"
import { zIndex } from "../../../assets/z-index"

const ModalLayoutContainer = styled.div`
  position: fixed; 
  top: 0; 
  left: 0; 
  width: 100%; 
  height: 100vh; 
  min-height: 100vh; 
  background: rgba(255,255,255,0.8);
  display: flex; 
  align-items: center; 
  justify-content: center; 
  z-index: ${zIndex.ssl};

`

export default ModalLayoutContainer