import { useContext } from "react"
import { StoreContext } from "../../lib/store/store"
import { LocalStorageConnector } from '../../lib/localstorage/localstorage'
import { getServicesRequest, IGetServicesRequestParams } from "../../lib/dime-api/requests/services/get-services-request"

export const useServiceService = () => {
  const store = useContext(StoreContext)

  const getServices = async (params?: IGetServicesRequestParams) => {
    try {
      store.setLoading(true)

      const token = LocalStorageConnector.getElement('token');

      const response = await getServicesRequest(token, params || {})
      store.setLoading(false)

      return response.data.results
    } catch (error) {
      console.log('getPolicies error', error)
      store.setLoading(false)
      throw error
    }
  }


  return {
    getServices,
  }
}