import React, { useState } from 'react'
import ReactTooltip from "react-tooltip";

import TablePoliciesContainer from './table-policies-style'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { Box, Button } from '@mui/material';
import { IAppPolicy } from '../../../entities/policy-entity';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ControlPointDuplicateIcon from '@mui/icons-material/ControlPointDuplicate';

interface ITableKeys {
  name: string;
  id: string;
  options?: {
    sort?: boolean
  }
}

const POLICY_TABLE_KEYS: ITableKeys[] = [
  {
    name: 'Name',
    id: 'name',
  },
  {
    name: 'Force most economic ride',
    id: 'forceMostEconomicRide',
  },
  {
    name: 'From time',
    id: 'fromTime',
  },
  {
    name: 'To time',
    id: 'toTime',
  },
  {
    name: 'Max monthly expense',
    id: 'maxMonthlyExpense',
  },
  {
    name: ' ',
    id: 'actions'
  }

]


interface TablePoliciesProps {
  policies: IAppPolicy[]
  options?: {
    pageLength?: number
  }
  events?: {
    onAddRow?: () => void,
    onEditRow?: (policy: IAppPolicy) => void,
    onDuplicateRow?: (policy: IAppPolicy) => void,
    onDeleteRow?: (policy: IAppPolicy) => void
  }
}

const usePagination = (props: TablePoliciesProps) => {
  const { options, policies } = props
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(options?.pageLength || policies.length);

  const getPaginatedRows = () => {
    if (options?.pageLength) {

      return policies.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    }

    return policies
  }

  return {
    page,
    setPage,
    rowsPerPage,
    getPaginatedRows
  }

}
const TablePolicies: React.FC<TablePoliciesProps> = (props: TablePoliciesProps) => {
  const { policies, events, options } = props;
  const {
    page,
    setPage,
    rowsPerPage,
    getPaginatedRows
  } = usePagination(props)

  return <TablePoliciesContainer>
    <TableContainer>
      <Table
        sx={{ minWidth: 750 }}
        aria-labelledby="table-policies"
      >
        <TableHead>
          <TableRow>
            {POLICY_TABLE_KEYS.map((tableKey) =>
              <TableCell
                key={tableKey.id}
                align={tableKey.id === 'actions' ? 'right' : 'left'}
                sortDirection={'desc'}
              >
                {tableKey.id === 'actions' && <Button
                  className="table-policies-add-button"
                  variant="contained"
                  color="success"
                  onClick={() => {
                    events.onAddRow && events.onAddRow()
                  }}
                >
                  Add new policy
                </Button>
                }

                {
                  tableKey.options?.sort
                    ? < TableSortLabel
                      active={true}
                      direction={'desc'}
                      onClick={() => { }}
                    >
                      {tableKey.name}
                    </TableSortLabel>
                    : <>{tableKey.name}</>
                }
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {policies.length > 0 &&
            getPaginatedRows()
              .map((policy, index) =>
                <TableRow
                  hover
                  onClick={(event) => { }}
                  role="checkbox"
                  tabIndex={-1}
                  key={policy.name}
                >
                  <TableCell
                    component="th"
                    id={policy.name}
                    scope="row"
                  >
                    {policy.name}
                  </TableCell>

                  <TableCell
                    align="left"
                  >
                    {policy.force_most_economic_ride ? '✔️' : '✖️'}
                  </TableCell>
                  <TableCell
                    align="left"
                  >
                    {policy.from_time || '-'}
                  </TableCell>
                  <TableCell
                    align="left"
                  >
                    {policy.to_time || '-'}
                  </TableCell>
                  <TableCell
                    align="left"
                  >
                    {policy.max_monthly_expense || '-'}
                  </TableCell>

                  <TableCell
                    align="right"
                  >
                    <div
                      className="row-actions"
                    >
                      <div
                        className="edit-icon row-action"
                        data-tip
                        data-for="edit-tooltip"
                        onClick={() => {
                          events.onEditRow && events.onEditRow(policy)
                        }}
                      >
                        <EditIcon />
                        <ReactTooltip
                          id="edit-tooltip"
                          backgroundColor="black"
                        >
                          Edit
                        </ReactTooltip>
                      </div>
                      <div
                        className="duplicate-icon row-action"
                        data-tip
                        data-for="duplicate-tooltip"
                        onClick={() => {
                          events.onDuplicateRow && events.onDuplicateRow(policy)
                        }}
                      >
                        <ControlPointDuplicateIcon />
                        <ReactTooltip
                          id="duplicate-tooltip"
                          backgroundColor="black"
                        >
                          Duplicate
                        </ReactTooltip>
                      </div>
                      <div
                        className="delete-icon row-action"
                        data-tip
                        data-for="delete-tooltip"
                        onClick={() => {
                          events.onDeleteRow && events.onDeleteRow(policy)
                        }}
                      >
                        <DeleteIcon />
                        <ReactTooltip
                          id="delete-tooltip"
                          backgroundColor="black"
                        >
                          Delete
                        </ReactTooltip>
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              )}
        </TableBody>
      </Table>
    </TableContainer>

    {options?.pageLength && <TablePagination
      rowsPerPageOptions={[options.pageLength]}
      component="div"
      count={policies.length}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={(event, newPage) => setPage(newPage)}
    />}
  </TablePoliciesContainer >
}

export default TablePolicies