export const zIndex = {
  auto: "auto",
  default: 1,
  xxs: 100,
  xs: 200,
  s: 300,
  m: 400,
  l: 500,
  xl: 600,
  ssl: 700,
};
