import { useContext } from "react"
import { StoreContext } from "../../lib/store/store"
import { LocalStorageConnector } from '../../lib/localstorage/localstorage'
import { getCompanyRequest } from "../../lib/dime-api/requests/companies/get-company-request"
import { getCompanyActiveSubscriptionRequest } from "../../lib/dime-api/requests/companies/subscription/get-company-subscription-request"
import { IAppCompany } from "../../entities/company-entity"
import { getCompanySubscriptionsRequest } from "../../lib/dime-api/requests/companies/subscription/get-company-subscriptions-request"
import { getCompanyPaymentMethodsRequest } from "../../lib/dime-api/requests/companies/payment-method/get-company-payment-methods-request"

export const useCompanyService = () => {
  const store = useContext(StoreContext)


  const getOwnCompany = async (): Promise<IAppCompany> => {
    try {

      const token = LocalStorageConnector.getElement('token');

      const companyRequest = await getCompanyRequest(token)
      const activeSubscriptionRequest = await getCompanyActiveSubscriptionRequest(token)
      const subscriptionsRequest = await getCompanySubscriptionsRequest(token)
      const paymentMethodsRequest = await getCompanyPaymentMethodsRequest(token)
      return {
        ...companyRequest.data,
        activeSubscription: activeSubscriptionRequest.data,
        subscriptions: subscriptionsRequest.data?.results,
        paymentMethods: paymentMethodsRequest.data
      }
    } catch (error) {
      console.log('getOwnCompany error', error)
      LocalStorageConnector.clearElement('token');
      throw error
    }
  }

  return {
    getOwnCompany
  }
}