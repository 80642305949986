import { Button, debounce, TextField, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import ModalLayout from '../../components/layout/modal-layout/modal-layout'
import ModalConfirm from '../../components/modals/modal-confirm/modal-confirm'
import modalConfirm from '../../components/modals/modal-confirm/modal-confirm'
import ModalError from '../../components/modals/modal-error/modal-error'
import TablePolicies from '../../components/tables/table-policies/table-policies'
import { routes } from '../../constants/routes'
import { IAppPolicy } from '../../entities/policy-entity'
import { useDebounce } from '../../hooks/useDebounce/use-debounce'
import { IGetPoliciesRequestParams } from '../../lib/dime-api/requests/policies/get-policies'
import { StoreContext } from '../../lib/store/store'
import { IStoreContext } from '../../lib/store/store-types'
import { usePolicyService } from '../../services/policy/policy-service'
import PoliciesContainer from './policies-style'

interface IPolicyAction {
  action: "EDIT" | "DUPLICATE" | "DELETE",
  policyId: number;
}

const getModalConfirmText = (verb: string) => {
  return `Are you sure you want to ${verb} the policy?`
}

const usePolicyData = () => {
  const [policyFilter, setPolicyFilter] = useState<IGetPoliciesRequestParams>({})
  const policyService = usePolicyService()
  const { debouncedValue, setValue: setSearchValue } = useDebounce(1000);

  const { data: policies, error, isLoading, refetch: refetchPolicies } = useQuery(
    ["policyList"],
    () => policyService.getPolicies(policyFilter),
    { retry: false },
  )

  useEffect(() => {
    console.log('refetch')
    refetchPolicies()
  }, [policyFilter])

  useEffect(() => {
    console.log('set filter', debouncedValue)

    if (debouncedValue) {
      setPolicyFilter({ search: debouncedValue })

    } else {
      setPolicyFilter({})
    }
  }, [debouncedValue])

  const searchPolicies = (search: string) => {
    setSearchValue(search)
  }

  return {
    policies,
    searchPolicies,
    setPolicyFilter
  }
}

const usePoliciesPage = () => {
  const navigate = useNavigate()
  const policyService = usePolicyService()

  const [policyAction, setPolicyAction] = useState<IPolicyAction>()
  const [modalConfirmText, setModalConfirmText] = useState<string>('')

  const {
    policies,
    searchPolicies
  } = usePolicyData()

  const handleDeletePolicy = async (policyId: number) => {
    await policyService.deletePolicy(String(policyId))
    setModalConfirmText('')
  }

  const handleDuplicatePolicy = (policyId: number) => {
    navigate(routes.policyEdit(String(policyId)))
  }

  const handleEditPolicy = (policyId: number) => {
    navigate(routes.policyEdit(String(policyId)))

  }

  const handleAddPolicy = () => {
    navigate(routes.policyCreate)
  }

  const handleClickPolicyAction = (newPolicyAction: IPolicyAction) => {
    const confirmText = getModalConfirmText(newPolicyAction.action);
    setModalConfirmText(confirmText)
    setPolicyAction(newPolicyAction)
  }

  const startPolicyAction = () => {
    switch (policyAction.action) {
      case 'EDIT':
        handleEditPolicy(policyAction.policyId)
        break;
      case 'DUPLICATE':
        handleDuplicatePolicy(policyAction.policyId)
        break;
      case 'DELETE':
        handleDeletePolicy(policyAction.policyId)
        break;
    }
  }

  return {
    handleAddPolicy,
    startPolicyAction,
    handleClickPolicyAction,
    modalConfirmText,
    setModalConfirmText,
    policies,
    searchPolicies
  }
}

const PoliciesPage: React.FC = () => {
  const actions = usePoliciesPage();
  return <PoliciesContainer>
    <Typography
      component="h1"
      variant="h4"
      className="policies-title"
    >
      Policies
    </Typography>

    <section className="policies-filter">
      <form
        className="policies-filter-form"
        id='policies-filter-form'
      >
        <TextField
          className="filter-field"
          required
          label="Search by Name"
          name="search-field"
          autoFocus
          onChange={(event) => { actions.searchPolicies(event.target.value) }}
          size={'small'}
        />

        {/* <Button
          className="policies-filter-button"
          type="submit"
          fullWidth
          variant="contained"
          color="success"
          form="payment-method-form"
        >
          Filter
        </Button> */}
      </form>
    </section>

    <section className="table-section">
      {actions.policies &&
        <TablePolicies
          policies={actions.policies}
          options={{
            pageLength: 15
          }}
          events={{
            onAddRow: () => actions.handleAddPolicy(),
            onEditRow: (policy) => actions.handleClickPolicyAction({
              action: 'EDIT',
              policyId: policy.id
            }),
            onDuplicateRow: (policy) => actions.handleClickPolicyAction({
              action: 'DUPLICATE',
              policyId: policy.id
            }),
            onDeleteRow: (policy) => actions.handleClickPolicyAction({
              action: 'DELETE',
              policyId: policy.id
            })
          }}
        />
      }
    </section>

    {actions.modalConfirmText &&
      <ModalLayout>
        <ModalConfirm
          title={{
            text: "Information",
            tag: "h2"
          }}
          description={actions.modalConfirmText}
          events={{
            onClose: () => actions.setModalConfirmText(''),
            onAccept: () => actions.startPolicyAction()
          }}
          modal={{
            ariaModal: true
          }}
        />
      </ModalLayout>}

  </PoliciesContainer>
}

export default PoliciesPage