import axios, { AxiosPromise } from 'axios'
import { getHeaders, SERVICE_URL } from '../../adapters/dime-api-adapter';
import { Policy } from '../../adapters/dime-api-client';

export const getPolicyByIdRequest = (
  token: string,
  id: string
): AxiosPromise<Policy> => {
  const endpoint = `${SERVICE_URL}/dashboard-api/policies/${id}/detail`;

  return axios({
    method: "GET",
    url: endpoint,
    headers: getHeaders(token),
  });
};