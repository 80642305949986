import styled from "@emotion/styled";
import { colors } from "../../assets/colors";
import { zIndex } from "../../assets/z-index";

const MainLoaderContainer = styled.div`
  position: fixed; 
  top: 0; 
  left: 0; 
  background: rgba(255,255,255,0.8);
  z-index: ${zIndex.xl};
  width: 100%; 
  min-height: 100vh; 
  height: 100vh; 
  max-height: 100vh; 
  display: flex; 
  align-items: center; 
  justify-content: center; 

  .main-loader-icon {
    width: 200px; 

    svg {
      circle {
        fill: ${colors.hardGreen};
      }
    }
  }
`

export default MainLoaderContainer