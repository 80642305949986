import { Button, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { id } from 'date-fns/locale';
import React, { useContext, useEffect, useState } from 'react'
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { IAppUser } from '../../../entities/user-entity';
import useForm from '../../../hooks/useForm/use-form';
import { IValidation, IFormValidations, ISubmitUseFormParams } from '../../../hooks/useForm/use-form-types';
import { RequiredValidation, EmailValidation } from '../../../hooks/useForm/use-form-validations';
import { StoreContext } from '../../../lib/store/store';
import { usePolicyService } from '../../../services/policy/policy-service';
import { useUserService } from '../../../services/user/user-service';
import UsersUpdateContainer from './users-update-style';

interface IFormValues {
  email: string;
  first_name: string;
  last_name: string;
  password: string;
  is_representative: boolean;
  policy: string;
}

const requiredValidation: IValidation = RequiredValidation();
const emailValidation: IValidation = EmailValidation()

const formValidations: IFormValidations<IFormValues> = {
  email: [requiredValidation, emailValidation],
  first_name: [],
  last_name: [],
  password: [requiredValidation],
  is_representative: [],
  policy: [requiredValidation]
}

const useUserData = () => {
  const userService = useUserService()
  const pathParams = useParams()
  const [user, setUser] = useState<IAppUser>()

  const { data: userData, error, isLoading } = useQuery(
    ["userUpdate"],
    () => userService.getUserById(pathParams.id),
    { retry: false },
  )

  useEffect(() => {
    if (!user) {
      setUser(userData)
    }
  }, [userData])

  return {
    user
  }
}

const useUserUpdateForm = () => {
  const userService = useUserService()
  const store = useContext(StoreContext)
  const { user } = useUserData()

  const initialValues: IFormValues = {
    email: user?.email || '',
    first_name: user?.first_name || '',
    last_name: user?.last_name || '',
    password: user?.password || '',
    is_representative: user?.is_representative || false,
    policy: user?.policy ? String(user?.policy) : '',
  }

  const submit = async (params: ISubmitUseFormParams<IFormValues>) => {
    await userService.updateUser(
      String(user.id),
      {
        ...params.values,
        policy: Number(params.values.policy)
      }
    )

    store.setModalInfo({
      open: true,
      text: 'The user has been updated successfully'
    })
  }

  const formActions = useForm<IFormValues>(
    {
      initialValues,
      formValidations,
      submit
    }
  )

  useEffect(() => {
    if (user && !formActions.values.email) {

      formActions.setAGroupOfValues({
        email: user?.email || '',
        first_name: user?.first_name || '',
        last_name: user?.last_name || '',
        password: user?.password || '',
        is_representative: user?.is_representative || false,
        policy: user?.policy ? String(user?.policy) : '',
      })
    }
  }, [user])

  return {
    ...formActions,
  }
}

const usePolicyData = () => {
  const policyService = usePolicyService()

  const { data: policies, error, isLoading, refetch: refetchPolicies } = useQuery(
    ["policyList"],
    () => policyService.getPolicies({}),
    { retry: false },
  )

  return { policies }
}

const UsersUpdatePage: React.FC = () => {
  const actions = useUserUpdateForm()
  const { policies } = usePolicyData()

  return <UsersUpdateContainer>
    <Typography
      component="h1"
      variant="h4"
      className="users-update-title"
    >
      Update user
    </Typography>

    <form
      className="users-update-form"
      id='users-update-form'
      onSubmit={(event) => {
        event.preventDefault();
        console.log(actions.values)
        actions.onSubmit()
      }}
    >

      <TextField
        className="users-update-field"
        fullWidth
        id="email"
        label="Email Address"
        name="email"
        autoFocus
        required
        value={actions.values.email}
        error={actions.errorValues.email.errorText ? true : false}
        helperText={actions.errorValues.email.errorText || ''}
        onChange={(event) => actions.handleFieldEvent(event.target.value, "email")}
      />

      <div
        className="users-update-2-row"
      >

        <TextField
          className="users-update-field"
          label="First Name"
          name="firstName"
          autoFocus
          value={actions.values.first_name}
          error={actions.errorValues.first_name.errorText ? true : false}
          helperText={actions.errorValues.first_name.errorText || ''}
          onChange={(event) => actions.handleFieldEvent(event.target.value, "first_name")}
        />

        <TextField
          className="users-update-field"
          label="Last Name"
          name="lastName"
          autoFocus
          value={actions.values.last_name}
          error={actions.errorValues.last_name.errorText ? true : false}
          helperText={actions.errorValues.last_name.errorText || ''}
          onChange={(event) => actions.handleFieldEvent(event.target.value, "last_name")}
        />
      </div>


      <TextField
        className="users-update-field password-field"
        required
        fullWidth
        name="password"
        label="Password"
        type="password"
        autoFocus
        value={actions.values.password}
        error={actions.errorValues.password.errorText ? true : false}
        helperText={actions.errorValues.password.errorText || ''}
        onChange={(event) => actions.handleFieldEvent(event.target.value, "password")}
      />
      <ul className="users-update-password-label">
        <li className="users-update-password-recomend">
          Your password can’t be too similar to your other personal information.
        </li>
        <li className="users-update-password-recomend">
          Your password must contain at least 8 characters.
        </li>
        <li className="users-update-password-recomend">
          Your password can’t be a commonly used password.
        </li>
        <li className="users-update-password-recomend">
          Your password can’t be entirely numeric.
        </li>
      </ul>

      <div className="users-update-field">
        <FormControlLabel
          label="Company representative"
          control={<Checkbox
            color="primary"
            onChange={(event) => actions.handleFieldEvent(event.target.checked as any, "is_representative")}
            inputProps={{
              'aria-label': 'Company representative',
            }}
          />}
        />
        <p className="error-text">{actions.errorValues.is_representative.errorText ? 'Required field' : ''}</p>
      </div>

      <div>
        <FormControl fullWidth>
          <InputLabel id="policy-select-label">Policy</InputLabel>
          <Select
            labelId="policy-select-label"
            value={Number(actions.values.policy)}
            label="Policy"
            error={actions.errorValues.policy.errorText ? true : false}
            onChange={(event) => actions.handleFieldEvent(event.target.value as any, "policy")}
          >
            {policies && policies.map((policy, index) => <MenuItem
              value={String(policy.id)}>
              {policy.name}
            </MenuItem>
            )}

          </Select>
        </FormControl>
        <p className="error-text">{actions.errorValues.policy.errorText ? 'Required field' : ''}</p>
      </div>
    </form>

    <Button
      className="users-update-button"
      type="submit"
      fullWidth
      variant="contained"
      color="success"
      form="users-update-form"
    >
      Update
    </Button>

  </UsersUpdateContainer>
}

export default UsersUpdatePage