import React, { useEffect } from "react";
import "./App.css";

import {

  Routes,
  Route,
  BrowserRouter
} from "react-router-dom";
import HomePage from "./pages/home/home";
import LoginPage from "./pages/login/login";
import MainLayout from "./components/layout/main-layout/main-layout";
import { StoreContext, useStoreContext } from "./lib/store/store";
import { useAuthService } from "./services/auth/auth-service";
import LayoutAuth from "./components/layout/auth-layout/auth-layout";

import { Helmet } from "react-helmet";
import { icons } from "./assets/icons/icons";
import { images } from "./assets/images/images";


const App: React.FC = () => {
  const storeContext = useStoreContext()


  return (
    <div className="app-container">
      <Helmet>
        <meta name="application-name" content="Ride Dime Dashboard" />
        <meta
          name="description"
          content="We compare all of your ride-sharing apps in one place, so you don't have to."
        />

        <meta
          property="og:title"
          content="Ride Dime. Save money, Save time"
        />
        <meta
          property="og:description"
          content="We compare all of your ride-sharing apps in one place, so you don't have to."
        />
        <meta charSet="utf-8" />
        <title>Dime</title>
        <meta name="robots" content="noindex" />

      </Helmet>
      <StoreContext.Provider value={storeContext}>
        <LayoutAuth />
      </StoreContext.Provider>
    </div >
  );
};

export default App;
