import { Drawer, IconButton, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import React, { useContext } from 'react'
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { styled, useTheme } from '@mui/material/styles';
import StoreIcon from '@mui/icons-material/Store';
import PaymentIcon from '@mui/icons-material/Payment';
import AddchartIcon from '@mui/icons-material/Addchart';
import PolicyIcon from '@mui/icons-material/Policy';
import ErrorIcon from '@mui/icons-material/Error';
import GroupIcon from '@mui/icons-material/Group';
import logoIcon from '../../assets/icons/files/logo-dime-black.svg'
import Svg from '../svg/svg';
import MainAsideStyle from './main-aside-style';
import { useAuthService } from '../../services/auth/auth-service';
import { Link } from 'react-router-dom';
import { routes } from '../../constants/routes';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { StoreContext } from '../../lib/store/store';

interface MainAsideProps {
  open: boolean;
  handleDrawerClose: () => void
}

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const useMainAside = () => {
  const authService = useAuthService()
  const store = useContext(StoreContext)

  const handleClickLogout = async () => {
    await authService.logout()
    window.location.reload()
  }

  return {
    handleClickLogout,
    profile: store.profile
  }
}


const MainAside: React.FC<MainAsideProps> = (props: MainAsideProps) => {
  const theme = useTheme();
  const {
    open,
    handleDrawerClose,

  } = props;

  const { handleClickLogout, profile } = useMainAside()

  return <MainAsideStyle>
    <Drawer
      className='main-aside'
      sx={{
        width: 240,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: 240,
          boxSizing: 'border-box',
        },
      }}
      variant="persistent"
      anchor="left"
      open={props.open}
    >
      <DrawerHeader className="drawer-header">
        <div className="logo">
          <Svg src={logoIcon} />
        </div>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List>
        <Link to={routes.company}>
          <ListItem key={"My Company"} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <StoreIcon />
              </ListItemIcon>
              <ListItemText primary={"My Company"} />
            </ListItemButton>
          </ListItem>
        </Link>

        <Link to={routes.monitoring}>
          <ListItem key={"Monitoring"} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <AddchartIcon />
              </ListItemIcon>
              <ListItemText primary={"Monitoring"} />
            </ListItemButton>
          </ListItem>
        </Link>

        {(profile?.is_superuser || profile?.is_staff) && <a href='https://backoffice.ridedime.app'>
          <ListItem key={"Backoffice"} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <AdminPanelSettingsIcon />
              </ListItemIcon>
              <ListItemText primary={"Backoffice"} />
            </ListItemButton>
          </ListItem>
        </a>}

        <Link to={routes.policies}>
          <ListItem key={"Policies"} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <PolicyIcon />
              </ListItemIcon>
              <ListItemText primary={"Policies"} />
            </ListItemButton>
          </ListItem>
        </Link>

        <Link to={routes.policyBreaches}>
          <ListItem key={"Policy breach"} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <ErrorIcon />
              </ListItemIcon>
              <ListItemText primary={"Policy breach"} />
            </ListItemButton>
          </ListItem>
        </Link>

        <Link to={routes.users}>
          <ListItem key={"Users"} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <GroupIcon />
              </ListItemIcon>
              <ListItemText primary={"Users"} />
            </ListItemButton>
          </ListItem>
        </Link>
      </List>
      <Divider />
      <List>
        <ListItem key={"Logout"} disablePadding>
          <ListItemButton onClick={async () => await handleClickLogout()}>
            <ListItemIcon>
              <GroupIcon />
            </ListItemIcon>
            <ListItemText primary={"Logout"} />
          </ListItemButton>
        </ListItem>
      </List>
    </Drawer>
  </MainAsideStyle >
}

export default MainAside