import { Button, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import React, { useContext } from 'react'
import { IValidation, IFormValidations, ISubmitUseFormParams } from '../../../hooks/useForm/use-form-types';
import UsersCreateContainer from './users-create-style'
import { RequiredValidation, EmailValidation } from '../../../hooks/useForm/use-form-validations';
import { useUserService } from '../../../services/user/user-service';
import { StoreContext } from '../../../lib/store/store';
import useForm from '../../../hooks/useForm/use-form';
import { useQuery } from 'react-query';
import { usePolicyService } from '../../../services/policy/policy-service';

interface IFormValues {
  email: string;
  first_name: string;
  last_name: string;
  password: string;
  is_representative: boolean;
  policy: string;
}

const requiredValidation: IValidation = RequiredValidation();
const emailValidation: IValidation = EmailValidation()

const formValidations: IFormValidations<IFormValues> = {
  email: [requiredValidation, emailValidation],
  first_name: [],
  last_name: [],
  password: [requiredValidation],
  is_representative: [],
  policy: [requiredValidation]
}

const useUserCreateForm = () => {
  const userService = useUserService()
  const store = useContext(StoreContext)

  const initialValues: IFormValues = {
    email: '',
    first_name: '',
    last_name: '',
    password: '',
    is_representative: false,
    policy: '',
  }

  const submit = async (params: ISubmitUseFormParams<IFormValues>) => {
    await userService.createUser({
      ...params.values,
      policy: Number(params.values.policy)
    })

    store.setModalInfo({
      open: true,
      text: 'The user has been created successfully'
    })
  }

  const formActions = useForm<IFormValues>(
    {
      initialValues,
      formValidations,
      submit
    }
  )

  return {
    ...formActions,
  }
}

const usePolicyData = () => {
  const policyService = usePolicyService()

  const { data: policies, error, isLoading, refetch: refetchPolicies } = useQuery(
    ["policyList"],
    () => policyService.getPolicies({}),
    { retry: false },
  )

  return { policies }
}


const UsersCreatePage: React.FC = () => {
  const actions = useUserCreateForm()
  const { policies } = usePolicyData()

  return <UsersCreateContainer>
    <Typography
      component="h1"
      variant="h4"
      className="users-create-title"
    >
      Create new user
    </Typography>

    <form
      className="users-create-form"
      id='users-create-form'
      onSubmit={(event) => {
        event.preventDefault();
        console.log(actions.values)
        actions.onSubmit()
      }}
    >

      <TextField
        className="users-create-field"
        fullWidth
        id="email"
        label="Email Address"
        name="email"
        autoComplete="email"
        autoFocus
        required
        error={actions.errorValues.email.errorText ? true : false}
        helperText={actions.errorValues.email.errorText || ''}
        onChange={(event) => actions.handleFieldEvent(event.target.value, "email")}
      />

      <div
        className="users-create-2-row"
      >

        <TextField
          className="users-create-field"
          label="First Name"
          name="firstName"
          autoFocus
          error={actions.errorValues.first_name.errorText ? true : false}
          helperText={actions.errorValues.first_name.errorText || ''}
          onChange={(event) => actions.handleFieldEvent(event.target.value, "first_name")}
        />

        <TextField
          className="users-create-field"
          label="Last Name"
          name="lastName"
          autoFocus
          error={actions.errorValues.last_name.errorText ? true : false}
          helperText={actions.errorValues.last_name.errorText || ''}
          onChange={(event) => actions.handleFieldEvent(event.target.value, "last_name")}
        />
      </div>

      <TextField
        className="users-update-field password-field"
        required
        fullWidth
        name="password"
        label="Password"
        type="password"
        autoComplete="current-password"
        autoFocus
        error={actions.errorValues.password.errorText ? true : false}
        helperText={actions.errorValues.password.errorText || ''}
        onChange={(event) => actions.handleFieldEvent(event.target.value, "password")}
      />
      <ul className="users-update-password-label">
        <li className="users-update-password-recomend">
          Your password can’t be too similar to your other personal information.
        </li>
        <li className="users-update-password-recomend">
          Your password must contain at least 8 characters.
        </li>
        <li className="users-update-password-recomend">
          Your password can’t be a commonly used password.
        </li>
        <li className="users-update-password-recomend">
          Your password can’t be entirely numeric.
        </li>
      </ul>


      <div className="users-create-field">
        <FormControlLabel
          label="Company representative"
          control={<Checkbox
            color="primary"
            onChange={(event) => actions.handleFieldEvent(event.target.checked as any, "is_representative")}
            inputProps={{
              'aria-label': 'Company representative',
            }}
          />}
        />
        <p className="error-text">{actions.errorValues.is_representative.errorText ? 'Required field' : ''}</p>
      </div>

      <div>
        <FormControl fullWidth>
          <InputLabel id="policy-select-label">Policy</InputLabel>
          <Select
            labelId="policy-select-label"
            value={actions.values.policy}
            label="Policy"
            error={actions.errorValues.policy.errorText ? true : false}
            onChange={(event) => actions.handleFieldEvent(event.target.value as any, "policy")}
          >
            {policies && policies.map((policy, index) => <MenuItem
              value={String(policy.id)}>
              {policy.name}
            </MenuItem>
            )}

          </Select>
        </FormControl>
        <p className="error-text">{actions.errorValues.policy.errorText ? 'Required field' : ''}</p>

      </div>
    </form>

    <Button
      className="users-create-button"
      type="submit"
      fullWidth
      variant="contained"
      color="success"
      form="users-create-form"
    >
      Create
    </Button>

  </UsersCreateContainer >
}

export default UsersCreatePage