import { TLocalStorageElements } from "./localstorage-types";

const getElement = (name: TLocalStorageElements): string => {
  if (localStorage.getItem(name) !== null) {
    return localStorage.getItem(name);
  }
  return '';
}

 const setElement = (name: TLocalStorageElements, value: string) => {
  localStorage.setItem(name, value)
}

 const clearElement  = (name: TLocalStorageElements) => {
  localStorage.removeItem(name)
}


export const LocalStorageConnector = {
  getElement,
  setElement, 
  clearElement
}
